import { Outlet, NavLink, Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import styles from "./Layout.module.css";
import { useApi } from "../../hooks/useApi";

interface User {
    email: string;
    is_admin: boolean;
    personas: any[];
}

const Layout = () => {
    const { getApiLoggedOnUser } = useApi();
    const [user, setUser] = useState<User | null>(null);

    useEffect(() => {
        const fetchLoggedOnUser = async () => {
            try {
                const loggedOnUser = await getApiLoggedOnUser();
                setUser(loggedOnUser);
            } catch (error) {
                console.error("Error fetching the logged-on user:", error);
            }
        };
        fetchLoggedOnUser();
    }, []); // Dependency array is empty, so the effect runs only on mount

    return (
        <div className={styles.layout}>
            <Outlet />
        </div>
    );
};

export default Layout;
