import { useEffect, useState } from "react";
import { ExtendedPersona } from "../../../api/personas";
import { AadUserResponse, ExtendedUserResponse } from "../../../api/users";
import { useApi } from "../../../hooks/useApi";
import { IconPlus, IconX } from "@tabler/icons-react";
import { Spinner } from "@fluentui/react";

interface Props {
    allUserIds: string[];
    isOpen: boolean;
    defaultPersonas: ExtendedPersona[];
    onClose: (addedUsers: ExtendedUserResponse[]) => void;
}

export const AddUserModal = ({ allUserIds, defaultPersonas, isOpen, onClose }: Props) => {
    const [isSaving, setIsSaving] = useState(false);
    const [existingUserIds, setExistingUserIds] = useState<string[]>([]);
    const [foundAadUsers, setFoundAadUsers] = useState<AadUserResponse[]>([]);
    const [usersToAdd, setUsersToAdd] = useState<AadUserResponse[]>([]);
    const { getApiUsersSearch, postApiCreateUser } = useApi();

    useEffect(() => {
        setExistingUserIds(allUserIds);
    }, [allUserIds, isOpen, onClose])

    const saveChanges = async () => {
        setIsSaving(true);

        const promises: Promise<any>[] = [];

        console.log("usersToAdd", usersToAdd)

        const newUsers = usersToAdd.filter(user => !(existingUserIds.includes(user.id))).map(user => ({
            ...user,
            user_id: user.id,
            is_admin: false,
            personas: defaultPersonas,
        }));

        console.log("new users", newUsers)

        newUsers.forEach(user => {
            console.log("adding user", user);
            promises.push(postApiCreateUser(user.email));
        });

        await Promise.all(promises);

        setIsSaving(false);

        setUsersToAdd([]);
        setFoundAadUsers([]);
        onClose(newUsers);
    }

    const handleCancel = () => {
        setUsersToAdd([]);
        setFoundAadUsers([]);
        onClose([]);
    }

    const handleSearch = async (event: { target: { value: string; }; }) => {
        setFoundAadUsers([]);
        const searchTerm = event.target.value;
        if (searchTerm.length < 3) return;

        const users = await getApiUsersSearch(searchTerm);

        if (users.length > 0) {
            setFoundAadUsers(users.filter(user => !usersToAdd.some(u => u.email == user.email)).map(user => ({
                ...user,
                user_id: user.id,
            })));
        }
    }

    const addUser = (user: AadUserResponse) => {
        if (usersToAdd.some(u => u.email === user.email)) return;
        console.log("adding user", user);
        setUsersToAdd((prev) => [...prev, user]);
        setFoundAadUsers((prev) => prev.filter(u => u.email !== user.email));
    }

    const removeUser = (user: AadUserResponse) => {
        setUsersToAdd((prev) => prev.filter(u => u.email !== user.email));
    }

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center">
            <div className="bg-white p-5 rounded-lg shadow-xl max-w-4xl w-full">
                <h2 className="text-lg font-semibold">Add Users</h2>

                <div className="flex mt-4 divide-x">
                    <div className="w-1/2 pr-2">
                        <h3 className="mb-4 font-semibold">Search Directory</h3>
                        <input
                            type="text"
                            placeholder="Search..."
                            className="mb-4 w-full p-2 border rounded"
                            onChange={handleSearch} // Implement the search logic here
                        />
                        <div className="overflow-y-auto h-64">
                            {foundAadUsers.length > 0 && foundAadUsers.map((user) => (
                                <div key={user.email} className="flex justify-between items-center mb-2 pb-3 shadow-sm">
                                    <span
                                        className="block overflow-hidden text-ellipsis whitespace-nowrap hover:max-w-none hover:whitespace-normal hover:text-ellipsis-none"
                                        title={user.email}
                                    >
                                        {user.email}
                                    </span>
                                    <button
                                        className="ml-4 bg-blue-500 mr-2 hover:bg-blue-700 text-white font-bold py-0 px-1 rounded"
                                        onClick={() => addUser(user)}
                                    >
                                        <IconPlus className="w-5" />
                                    </button>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="w-1/2 pl-2">
                        <h3 className="mb-4 font-semibold">Selected Users</h3>
                        <div className="overflow-y-auto h-64">
                            {usersToAdd.length > 0 ? (
                                usersToAdd.map((user) => (
                                    <div key={user.email} className="flex justify-between items-center mb-2 shadow-sm pb-3">
                                        <span
                                            className="block overflow-hidden text-ellipsis whitespace-nowrap hover:max-w-none hover:whitespace-normal hover:text-ellipsis-none"
                                            title={user.email}
                                        >
                                            {user.email}
                                        </span>
                                        <button
                                            className="ml-4 bg-red-500 hover:bg-red-700 text-white py-0 px-1 rounded"
                                            onClick={() => removeUser(user)}
                                        >
                                            <IconX className="w-5" />
                                        </button>
                                    </div>
                                ))
                            ) : (
                                <div className="flex items-center justify-center h-full">
                                    <p className="text-center text-gray-500">
                                        No users selected. <br /> Please use the search on the left to find and add users.
                                    </p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                <div className="mt-4 flex justify-end">
                    <div>
                        <button
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 h-10 w-24 px-4 rounded mr-2 flex items-center justify-center"
                            onClick={saveChanges}
                        >

                            {isSaving ? <Spinner /> : 'Submit'}
                        </button>
                    </div>
                    <div>
                        <button
                            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 h-10 w-24 px-4 rounded mr-2 flex items-center justify-center"
                            onClick={handleCancel}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}