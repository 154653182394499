import { Dispatch, createContext } from 'react';

import { ActionType } from '../../hooks/useCreateReducer';

import { Prompt } from '../../types/prompt';

import { PromptbarInitialState } from './Promptbar.state';
import { PromptResponse } from '../../api/prompts';

export interface PromptbarContextProps {
  state: PromptbarInitialState;
  dispatch: Dispatch<ActionType<PromptbarInitialState>>;
  handleCreatePrompt: (isShared: boolean) => void;
  handleDeletePrompt: (prompt: PromptResponse, isShared: boolean) => void;
  handleUpdatePrompt: (prompt: PromptResponse, isShared: boolean) => void;
}

const PromptbarContext = createContext<PromptbarContextProps>(undefined!);

export default PromptbarContext;
