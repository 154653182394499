import { useState, useEffect } from 'react';
import { SharepointSite, SharepointFolder } from '../../../api/personas';
import '../../../styles/globals.css';
import { Tooltip } from 'react-tooltip';

function FolderSelectionModal(props: any) {
    // Mapping folderType to the correct key in props.site
    const folderKey = props.folderType === 'Included' ? 'included_folders' : 'excluded_folders';

    // Initialize the folders state based on the folderKey
    const [localFolders, setLocalFolders] = useState<SharepointFolder[]>(props.site[props.folderKey] || []);


    useEffect(() => {
        // Update localFolders when props.site or props.folderKey changes
        setLocalFolders(props.site[props.folderKey] || []);
    }, [props.site, props.folderKey]);

    // Function to handle the deletion of a folder
    const handleDeleteFolder = (index: number) => {
        const updatedFolders = localFolders.filter((_, folderIndex) => folderIndex !== index);
        setLocalFolders(updatedFolders);
    };

    const handleFolderChange = (index: number, field: 'document_library_name' | 'folder_path', value: string) => {
        // Clone the current folders to avoid direct mutation
        const updatedFolders = localFolders.map((folder, folderIndex) => {
            if (index === folderIndex) {
                return { ...folder, [field]: value };
            }
            return folder;
        });

        // Update the local state with the new folders
        setLocalFolders(updatedFolders);
    };

    useEffect(() => {
        setLocalFolders(props.site[folderKey] || []);
    }, [props.site, folderKey]);

    const handleClose = () => {
        // Call the parent component's handleFolderChange function with the current state
        props.handleFolderChange(localFolders, props.folderType, props.siteIndex);
        // Hide the modal
        props.onHide();
    };

    // Function to add a new folder to the folders state
    const addNewFolder = () => {
        // Create a new folder with empty document_library_name and folder_path
        const newFolder = { document_library_name: "Documents", folder_path: "/" };
        // Add the new folder to the folders state
        setLocalFolders(prevFolders => [...prevFolders, newFolder]);
    };


    return (
        <div className={`fixed inset-0 z-50 overflow-auto bg-smokeLight flex items-center justify-center ${props.show ? '' : 'hidden'}`}>
            <div className="relative mx-auto p-5 border shadow-lg rounded-md bg-white w-full max-w-4xl h-4/5 max-h-full flex flex-col">
                <div className="flex-grow overflow-y-auto p-3">
                    <h3 className="text-lg leading-6 font-medium text-gray-900 text-center">
                        Editing {props.folderType} Folders For Site Path: {props.site.site_path}
                    </h3>

                    {props.folderType === 'Included' ? (
                        <p className="mt-2 text-sm text-gray-500 text-center">
                            The folders listed below will be explicitly included in the persona.  All other folders will be excluded.
                        </p>
                    ) : (
                        <p className="mt-2 text-sm text-gray-500 text-center">
                            The folders listed below will be explicitly excluded from the persona.
                        </p>
                    )}

                    {/* Table for SharePoint Folders */}
                    <table className="min-w-full divide-y divide-gray-200 mt-2">
                        <thead className="bg-gray-50">
                            <tr>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Action
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Document Library Name
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Folder Path
                                </th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            {localFolders.map((folder, index) => (
                                <tr key={index}>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <button
                                            type="button"
                                            onClick={() => handleDeleteFolder(index)}
                                            className="text-red-600 hover:text-red-900"
                                        >
                                            Delete
                                        </button>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <input
                                            type="text"
                                            value={folder.document_library_name}
                                            onChange={(e) => handleFolderChange(index, 'document_library_name', e.target.value)}
                                            className="form-input rounded-md shadow-sm mt-1 block w-full p-1"
                                            style={{ color: "black" }}
                                            placeholder='Documents'
                                            data-tooltip-id='doc-library-tooltip'
                                            data-tooltip-place='bottom'
                                        />
                                        <Tooltip id="doc-library-tooltip" className='max-w-[55%]'>
                                            <div >
                                                <span className='whitespace-normal font-large'>
                                                    To find the name of the document library, navigate to the document library in SharePoint.
                                                </span>
                                            </div>
                                        </Tooltip>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <input
                                            type="text"
                                            value={folder.folder_path}
                                            onChange={(e) => handleFolderChange(index, 'folder_path', e.target.value)}
                                            className="form-input rounded-md shadow-sm mt-1 block w-full p-1"
                                            style={{ color: "black" }}
                                            placeholder='/path/to/folder'
                                            data-tooltip-id='folder-path-tooltip'
                                            data-tooltip-place='bottom'
                                        />
                                        <Tooltip id="folder-path-tooltip" className='max-w-[55%]'>
                                            <div >
                                                <span className='whitespace-normal font-large'>
                                                    This is the path to the folder within the document library.  It should start with a forward slash, e.g. /path/to/folder.  To include all folders in the document library, enter a forward slash.
                                                </span>
                                            </div>
                                        </Tooltip>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className="flex justify-end mt-4">
                        <button
                            type="button"
                            onClick={addNewFolder} // Add new folder row when this button is clicked
                            className="inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-500 text-base font-medium text-white hover:bg-green-700 focus:outline-none sm:text-sm"
                        >
                            Add New Folder
                        </button>
                    </div>
                </div>
                <div className="bg-white border-t">
                    {/* Fixed Action Buttons */}
                    <button type="button" className="mt-4 inline-flex w-full justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-500 text-base font-medium text-white hover:bg-red-700 focus:outline-none sm:text-sm" onClick={handleClose}>
                        Close Editor (Changes are cached automatically)
                    </button>
                </div>
            </div>
        </div>
    );
}

export default FolderSelectionModal;