import { ChangeEvent, useEffect, useState } from "react";
import { useApi } from "../../../hooks/useApi";
import { IconPlus, IconX } from "@tabler/icons-react";
import { Spinner } from "@fluentui/react";
import { EntraGroup } from "../../../api/groups";
import { ThreeDots } from "react-loader-spinner";

interface Props {
    personaId: string;
    isOpen: boolean;
    onHide: () => void;
}

export const PersonaGroupsManagementModal = ({ personaId, isOpen, onHide }: Props) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isSaving, setIsSaving] = useState(false);
    const [assignedGroups, setAssignedGroups] = useState<EntraGroup[]>([]);
    const [foundEntraGroups, setFoundEntraGroups] = useState<EntraGroup[]>([]);

    const { getApiGroupsSearch, getApiPersonaAssignedGroups, postApiSetPersonaAssignedGroups } = useApi();

    useEffect(() => {
        if (isOpen) {
            setIsLoading(true);

            const fetchAssignedGroups = async () => {
                const groups = await getApiPersonaAssignedGroups(personaId);
                setAssignedGroups(groups);
                setIsLoading(false);
            }

            fetchAssignedGroups();
        }
    }, [personaId, isOpen])

    const saveChanges = async () => {
        setIsSaving(true);

        console.log("assigned groups", assignedGroups)

        await postApiSetPersonaAssignedGroups(personaId, assignedGroups);

        setIsSaving(false);

        setFoundEntraGroups([]);
        onHide();
    }

    const handleCancel = () => {
        setAssignedGroups([]);
        setFoundEntraGroups([]);
        onHide();
        // onClose([]);
    }

    const handleSearch = async (event: ChangeEvent<HTMLInputElement>) => {
        setFoundEntraGroups([]);
        const searchTerm = event.target.value;
        if (searchTerm.length < 3) return;

        const groups = await getApiGroupsSearch(searchTerm);

        if (groups.length > 0) {
            setFoundEntraGroups(groups.filter(group => !assignedGroups.some(g => g.id == group.id)));
        }
    }

    const addGroup = (group: EntraGroup) => {
        setAssignedGroups((prev) => [...prev, group]);
        setFoundEntraGroups((prev) => prev.filter(g => g.id !== group.id));
    }

    const removeUser = (group: EntraGroup) => {
        setAssignedGroups((prev) => prev.filter(g => g.id !== group.id));
    }

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center text-black">
            <div className="bg-white p-5 rounded-lg shadow-xl max-w-4xl w-full">
                <h2 className="text-lg font-semibold">Manage Groups</h2>

                <div className="flex mt-4 divide-x">
                    <div className="w-1/2 pr-2">
                        <h3 className="mb-4 font-semibold">Search Directory</h3>
                        <input
                            type="text"
                            placeholder="Search..."
                            className="mb-4 w-full p-2 border rounded"
                            onChange={handleSearch} // Implement the search logic here
                        />
                        <div className="overflow-y-auto h-64">
                            {foundEntraGroups.length > 0 && foundEntraGroups.map((group) => (
                                <div key={group.id} className="flex justify-between items-center mb-2 pb-3 shadow-sm">
                                    <span
                                        className="block overflow-hidden text-ellipsis whitespace-nowrap hover:max-w-none hover:whitespace-normal hover:text-ellipsis-none"
                                        title={group.display_name}
                                    >
                                        {group.display_name}
                                    </span>
                                    <button
                                        className="ml-4 bg-blue-500 mr-2 hover:bg-blue-700 text-white font-bold py-0 px-1 rounded"
                                        onClick={() => addGroup(group)}
                                    >
                                        <IconPlus className="w-5" />
                                    </button>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="w-1/2 pl-2">
                        <h3 className="mb-4 font-semibold">Assigned Groups</h3>
                        <div className="overflow-y-auto h-64">
                            {isLoading && (
                                <div>
                                    <ThreeDots color="#202123" height={50} width={50} wrapperClass='flex justify-center items-center' />
                                </div>
                            ) || (assignedGroups.length > 0 ? (
                                assignedGroups.map((group) => (
                                    <div key={group.id} className="flex justify-between items-center mb-2 shadow-sm pb-3">
                                        <span
                                            className="block overflow-hidden text-ellipsis whitespace-nowrap hover:max-w-none hover:whitespace-normal hover:text-ellipsis-none"
                                            title={group.display_name}
                                        >
                                            {group.display_name}
                                        </span>
                                        <button
                                            className="ml-4 bg-red-500 hover:bg-red-700 text-white py-0 px-1 rounded"
                                            onClick={() => removeUser(group)}
                                        >
                                            <IconX className="w-5" />
                                        </button>
                                    </div>
                                ))
                            ) : (
                                <div className="flex items-center justify-center h-full">
                                    <p className="text-center text-gray-500">
                                        No groups selected. <br /> Please use the search on the left to find and add groups.
                                    </p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                <div className="mt-4 flex justify-end">
                    <div>
                        <button
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 h-10 w-24 px-4 rounded mr-2 flex items-center justify-center"
                            onClick={saveChanges}
                        >
                            {isSaving ? <Spinner /> : 'Submit'}
                        </button>
                    </div>
                    <div>
                        <button
                            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 h-10 w-24 px-4 rounded mr-2 flex items-center justify-center"
                            onClick={handleCancel}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}