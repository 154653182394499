import { IconFile, IconFileExport, IconPlus, IconSettings } from '@tabler/icons-react';
import { useContext, useState } from 'react';

import HomeContext from '../../../pages/chatv2/Chatv2.context';

import { SettingDialog } from '../../../components/Settings/SettingDialog';

import { SidebarButton } from '../../Sidebar/SidebarButton';
import ChatbarContext from '../Chatbar.context';
import { ClearConversations } from './ClearConversations';
import { ChatFilesDialog } from '../../Files/ChatFilesDialog';


// @ts-ignore
const ENABLE_CHAT_FILES = window.FLAG_CHAT_FILES;

export const ChatbarSettings = () => {
  const [isSettingDialogOpen, setIsSettingDialog] = useState<boolean>(false);
  const [isChatFileDialogOpen, setIsChatFileDialog] = useState<boolean>(false);

  const {
    state: {
      chats,
    },
    dispatch: homeDispatch,
  } = useContext(HomeContext);

  const {
    handleClearConversations,
  } = useContext(ChatbarContext);

  return (
    <div className="flex flex-col items-center space-y-1 border-t border-white/20 pt-1 text-sm">
      {chats.length > 0 ? (
        <ClearConversations onClearConversations={handleClearConversations} />
      ) : null}

      {ENABLE_CHAT_FILES && (
        <>
          <SidebarButton
            text={'Chat files'}
            icon={<IconFile size={18} />}
            onClick={() => setIsChatFileDialog(true)}
          />

          <ChatFilesDialog
            open={isChatFileDialogOpen}
            onClose={() => {
              setIsChatFileDialog(false);
            }}
          />
        </>
      )}

      <SidebarButton
        text={'Settings'}
        icon={<IconSettings size={18} />}
        onClick={() => setIsSettingDialog(true)}
      />

      <SettingDialog
        open={isSettingDialogOpen}
        onClose={() => {
          setIsSettingDialog(false);
        }}
      />
    </div>
  );
};
