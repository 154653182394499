import { ChatSummary } from '../../../api/chat';
import { Conversation } from '../../../types/chat';

import { ChatComponent } from './Chat';

interface Props {
  chats: ChatSummary[];
}

export const Chats = ({ chats: chats }: Props) => {
  return (
    <div className="flex w-full flex-col gap-1">
      {chats
        .filter((chat) => !chat.folder_id)
        .slice()
        // sort by last updated - most recent first
        .sort((a, b) => {
          const dateA = new Date(a.last_updated_at);
          const dateB = new Date(b.last_updated_at);
          return dateB.getTime() - dateA.getTime();
        })
        .map((chat, index) => (
          <ChatComponent key={index} chat={chat} />
        ))}
    </div>
  );
};
