import { useEffect, useState } from "react";
import { ExtendedPersona } from "../../../api/personas";
import { ExtendedUserResponse, UserResponse } from "../../../api/users";
import { useApi } from "../../../hooks/useApi";
import { Spinner } from "@fluentui/react";

interface ModalPersona extends ExtendedPersona {
    checked: boolean;
}

interface Props {
    user: ExtendedUserResponse | null;
    allPersonas: ExtendedPersona[];
    isOpen: boolean;
    onClose: any;
}

export const UserPersonaModal = ({ user, allPersonas, isOpen, onClose }: Props) => {
    const [isSaving, setIsSaving] = useState(false);
    const [initialPersonaStatuses, setInitialPersonaStatuses] = useState<ModalPersona[]>([]);
    const [changedPersonaStatuses, setChangedPersonaStatuses] = useState<ModalPersona[]>([]);
    const { postAPIAddPersonaToUser, deleteApiUserFromPersona } = useApi();

    useEffect(() => {
        if (user && user.personas) {
            const statuses = allPersonas.map(persona => ({
                ...persona,
                checked: user.personas.some(userPersona => userPersona.id === persona.id),
            }));
            setInitialPersonaStatuses(statuses);
            setChangedPersonaStatuses(statuses);
        }
    }, [user, allPersonas, isOpen, onClose])

    const handlePersonaChange = (persona: ExtendedPersona) => {
        const newPersonaStatuses = changedPersonaStatuses.map(personaStatus => {
            if (personaStatus.id === persona.id) {
                return {
                    ...personaStatus,
                    checked: !personaStatus.checked,
                }
            }
            return personaStatus;
        });
        setChangedPersonaStatuses(newPersonaStatuses);
    }

    const saveChanges = async () => {
        if (user) {
            setIsSaving(true);

            const personasToUpdate = changedPersonaStatuses.filter(p => !p.is_default);

            // Use a for...of loop to await each API call
            for (const newPersonaStatus of personasToUpdate) {
                const initialStatus = initialPersonaStatuses.find(status => status.id === newPersonaStatus.id);

                if (initialStatus && initialStatus.checked !== newPersonaStatus.checked) {
                    if (newPersonaStatus.checked) {
                        console.log('adding', newPersonaStatus)
                        const r = await postAPIAddPersonaToUser(user.user_id, newPersonaStatus.id)
                        console.log('added', r)
                    } else {
                        console.log('removing', newPersonaStatus)
                        const r = await deleteApiUserFromPersona(user.user_id, newPersonaStatus.id)
                        console.log('removed', r)
                    }
                }
            }

            setIsSaving(false);
            onClose();
        }
    }

    if (!isOpen || user == null) return null;

    return (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center">
            <div className="bg-white p-5 rounded-lg shadow-xl">
                <h2 className="text-lg font-semibold">Manage Personas</h2>
                <div className="mt-2">
                    {/* Here you would render all available personas */}
                    {changedPersonaStatuses.map((persona) => (
                        <div key={persona.id} className="flex items-center">

                            <input
                                type="checkbox"
                                checked={persona.checked || persona.is_default}
                                disabled={persona.is_default}
                                onChange={() => handlePersonaChange(persona)}
                                className="form-checkbox"
                            />
                            <span className="ml-2">{persona.name}</span>
                        </div>
                    ))}
                </div>
                <div className="flex items-center space-x-4">
                    <button className="mt-4 w-20 px-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={saveChanges}>
                        {isSaving ? <Spinner /> : 'Save'}
                    </button>
                    <button className="mt-4 w-20 px-4 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded" onClick={onClose}>
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    );
}