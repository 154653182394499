import { useCallback } from "react";
import { useMsal } from "@azure/msal-react";
import { AuthenticationResult, InteractionRequiredAuthError, SilentRequest } from "@azure/msal-browser";

// @ts-ignore
const API_AUTH_SCOPE = window.API_AUTH_SCOPE;
// @ts-ignore
const API_BASE_URL = window.API_BASE_URL;

export const useAuthToken = () => {
    const { instance, accounts } = useMsal();

    const acquireAuthToken = useCallback(async (): Promise<AuthenticationResult> => {
        const authRequest: SilentRequest = {
            scopes: [API_AUTH_SCOPE],
            account: accounts[0],
        };

        let authResult;

        try {
            authResult = await instance.acquireTokenSilent(authRequest);
        } catch (error) {
            if (error instanceof InteractionRequiredAuthError) {
                authResult = await instance.acquireTokenPopup(authRequest);
            } else {
                throw error;
            }
        }

        return await refreshTokenIfRequired(authResult);
    }, [API_BASE_URL, instance, accounts]);

    const refreshTokenIfRequired = useCallback(async (authResult: AuthenticationResult): Promise<AuthenticationResult> => {
        const now = Math.floor(Date.now() / 1000);

        const idTokenClaims = authResult.idTokenClaims as { exp: number };

        // refresh token if it's expired
        if (idTokenClaims.exp < now) {
            console.log(`Refreshing token.  now: ${now}, exp: ${idTokenClaims.exp}`)
            const authRequest: SilentRequest = {
                scopes: [API_AUTH_SCOPE],
                account: accounts[0]
            };
            return await instance.acquireTokenPopup(authRequest);
        }

        return authResult;
    }, [API_BASE_URL, instance, accounts]);

    const acquireSessionToken = useCallback(async (authResult: AuthenticationResult): Promise<string> => {
        const sessionTokenResult = await fetch(`${API_BASE_URL}/.auth/login/aad`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ id_token: authResult.idToken, access_token: authResult.accessToken }),
        });

        return (await sessionTokenResult.json()).authenticationToken;
    }, [API_BASE_URL, instance, accounts]);

    return {
        acquireAuthToken,
        acquireSessionToken
    };
};
