export interface PromptResponse {
    id: string;
    name: string;
    description: string;
    content: string;
    created_at: Date;
    last_updated_at: Date;
    folder_id: string | null;
}

export interface UpsertUserPromptRequest {
    name: string;
    description: string;
    content: string;
}

type CallApiFunction = (method: string, path: string, data: object | null, abortController: AbortController) => Promise<Response | null>;

export const getAllUserPrompts = async (callApi: CallApiFunction): Promise<PromptResponse[]> => {
    const path = '/api/v1/user_prompts';
    const response = await callApi("GET", path, null, new AbortController());

    if (!response || !response.ok) {
        throw new Error("Failed to get user prompts.");
    }

    const prompts = await response.json() as PromptResponse[];
    return prompts;
}

export const getUserPrompt = async (callApi: CallApiFunction, promptId: string): Promise<PromptResponse> => {
    const path = `/api/v1/user_prompts/${promptId}`;
    const response = await callApi("GET", path, null, new AbortController());

    if (!response || !response.ok) {
        throw new Error("Failed to get user prompts.");
    }

    const prompt = await response.json() as PromptResponse;
    return prompt;
}

export const newUserPrompt = async (callApi: CallApiFunction, createPromptRequest: UpsertUserPromptRequest): Promise<PromptResponse> => {
    const path = `/api/v1/user_prompts`;
    const response = await callApi("POST", path, createPromptRequest, new AbortController());

    if (!response || !response.ok) {
        throw new Error("Failed to get user prompts.");
    }

    const prompt = await response.json() as PromptResponse;
    return prompt;
}

export const updateUserPrompt = async (callApi: CallApiFunction, updatePromptRequest: UpsertUserPromptRequest, promptId: string): Promise<PromptResponse> => {
    const path = `/api/v1/user_prompts/${promptId}`;
    const response = await callApi("PUT", path, updatePromptRequest, new AbortController());

    if (!response || !response.ok) {
        throw new Error("Failed to update user prompt.");
    }

    const prompt = await response.json() as PromptResponse;
    return prompt;
}

export const deleteUserPrompt = async (callApi: CallApiFunction, promptId: string): Promise<Response> => {
    const path = `/api/v1/user_prompts/${promptId}`;
    const response = await callApi("DELETE", path, null, new AbortController());

    if (!response || !response.ok) {
        throw new Error("Failed to delete user prompt.");
    }

    return response;
}

export const getAllSharedPrompts = async (callApi: CallApiFunction): Promise<PromptResponse[]> => {
    const path = '/api/v1/shared_prompts';
    const response = await callApi("GET", path, null, new AbortController());

    if (!response || !response.ok) {
        throw new Error("Failed to get user prompts.");
    }

    const prompts = await response.json() as PromptResponse[];
    return prompts;
}

export const getSharedPrompt = async (callApi: CallApiFunction, promptId: string): Promise<PromptResponse> => {
    const path = `/api/v1/shared_prompts/${promptId}`;
    const response = await callApi("GET", path, null, new AbortController());

    if (!response || !response.ok) {
        throw new Error("Failed to get user prompts.");
    }

    const prompt = await response.json() as PromptResponse;
    return prompt;
}

export const newSharedPrompt = async (callApi: CallApiFunction, createPromptRequest: UpsertUserPromptRequest): Promise<PromptResponse> => {
    const path = `/api/v1/shared_prompts`;
    const response = await callApi("POST", path, createPromptRequest, new AbortController());

    if (!response || !response.ok) {
        throw new Error("Failed to get user prompts.");
    }

    const prompt = await response.json() as PromptResponse;
    return prompt;
}

export const updateSharedPrompt = async (callApi: CallApiFunction, updatePromptRequest: UpsertUserPromptRequest, promptId: string): Promise<PromptResponse> => {
    const path = `/api/v1/shared_prompts/${promptId}`;
    const response = await callApi("PUT", path, updatePromptRequest, new AbortController());

    if (!response || !response.ok) {
        throw new Error("Failed to update user prompt.");
    }

    const prompt = await response.json() as PromptResponse;
    return prompt;
}

export const deleteSharedPrompt = async (callApi: CallApiFunction, promptId: string): Promise<Response> => {
    const path = `/api/v1/shared_prompts/${promptId}`;
    const response = await callApi("DELETE", path, null, new AbortController());

    if (!response || !response.ok) {
        throw new Error("Failed to delete user prompt.");
    }

    return response;
}