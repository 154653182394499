import { Dispatch, createContext } from 'react';

import { ActionType } from '../../hooks/useCreateReducer';

import { Conversation } from '../../types/chat';

import { ChatbarInitialState } from './Chatbar.state';
import { ChatSummary } from '../../api/chat';

export interface ChatbarContextProps {
  state: ChatbarInitialState;
  dispatch: Dispatch<ActionType<ChatbarInitialState>>;
  handleDeleteChat: (chat: ChatSummary) => void;
  handleClearConversations: () => void;
}

const ChatbarContext = createContext<ChatbarContextProps>(undefined!);

export default ChatbarContext;
