import { Outlet, NavLink, Link } from "react-router-dom";
import React, {useEffect, useState} from "react";

import styles from "./Layout.module.css";

import shyrkaLogo from '../../assets/ProjectShyrka_Icon.png';
import { useApi } from '../../hooks/useApi';

interface User {
    email: string;
    is_admin: boolean;
    personas: any[]; 
}

const Layout = () => {
    const { getApiLoggedOnUser } = useApi();
    const [user, setUser] = useState<User | null>(null);
    
    useEffect(() => {
        const fetchLoggedOnUser = async () => {
            try {
                const loggedOnUser = await getApiLoggedOnUser();
                setUser(loggedOnUser);
            } catch (error) {
                console.error('Error fetching the logged-on user:', error);
            }
        };
        fetchLoggedOnUser();
    }, []); // Dependency array is empty, so the effect runs only on mount
    
    return (
        <div className={styles.layout }>
            <header className={styles.header + ' flex-shrink-0'} role={"banner"}>
                <div className={styles.headerContainer}>
                    <Link to="/" className={styles.headerTitleContainer}>
                        <h3 className={styles.headerTitle}>Shyrka</h3> <img src={shyrkaLogo} className="h-8 inline pl-2" />
                    </Link>
                    
                    <nav className="hidden">
                        <ul className={styles.headerNavList}>
                            <li>
                                <NavLink to="/basic" className={({ isActive }) => (isActive ? styles.headerNavPageLinkActive : styles.headerNavPageLink)}>
                                    Basic
                                </NavLink>
                            </li>
                            <li className={styles.headerNavLeftMargin}>
                                <NavLink to="/" className={({ isActive }) => (isActive ? styles.headerNavPageLinkActive : styles.headerNavPageLink)}>
                                    Advanced
                                </NavLink>
                            </li>
                            {/* <li className={styles.headerNavLeftMargin}>
                                <a href="https://aka.ms/entgptsearch" target={"_blank"} title="Github repository link">
                                    <img
                                        src={github}
                                        alt="Github logo"
                                        aria-label="Link to github repository"
                                        width="20px"
                                        height="20px"
                                        className={styles.githubLogo}
                                    />
                                </a>
                            </li> */}
                        </ul>
                    </nav>
                    <h4 className={styles.headerRightText + ' hidden lg:block'}>Sundown Solutions</h4>
                    {user && user.is_admin && (
                        <Link to="/admin/adminHome" style={{ backgroundColor: 'rgb(88, 108, 205)' }} className="text-white font-bold py-2 px-4 rounded hover:bg-blue-700 transition-colors duration-200">
                            Admin Controls
                        </Link>
                    )}
                </div>
            </header>

            <Outlet />
        </div>
    );
};

export default Layout;
