type CallApiFunction = (method: string, path: string, data: object | null, abortController: AbortController) => Promise<Response | null>;

export const searchUserChatMessages = async (callApi: CallApiFunction, query: string): Promise<string[]> => {
    const path = `/api/v1/search/chats?query=${query}`;
    const response = await callApi("GET", path, null, new AbortController());

    if (!response || !response.ok) {
        throw new Error("Failed to search user chats.");
    }

    const ids = await response.json() as string[];
    return ids;
}

