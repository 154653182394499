import { MutableRefObject, memo, useCallback, useContext, useEffect, useRef, useState } from "react";

import { throttle } from "../../utils/data/throttle";

import HomeContext from "../../pages/chatv2/Chatv2.context";

import { ChatInput } from "./ChatInput";
import { ChatLoader } from "./ChatLoader";
import { MemoizedChatMessage } from "./MemoizedChatMessage";
import { useApi } from "../../hooks/useApi";
import { ChatMessage, ChatResponse, UpdateChatRequest, getUserChat } from "../../api/chat";
import toast from "react-hot-toast";
import { Persona } from "../../api/users";

interface Props {
    stopConversationRef: MutableRefObject<boolean>;
    slim: boolean;
    defaultPersona: string;
    assistantName: string | null;
}

export const Chat = memo(({ stopConversationRef, slim, defaultPersona, assistantName }: Props) => {
    const {
        state: { selectedChatSummary: selectedChat, chats, apiKey, loading },
        handleUpdateChat: handleUpdateChat,
        dispatch: homeDispatch
    } = useContext(HomeContext);

    const [loadingChat, setLoadingChat] = useState<boolean>(false);
    const [currentChat, setCurrentChat] = useState<ChatResponse>();
    const [currentMessage, setCurrentMessage] = useState<ChatMessage>();
    const [autoScrollEnabled, setAutoScrollEnabled] = useState<boolean>(true);
    const [showSettings, setShowSettings] = useState<boolean>(false);
    const [showScrollDownButton, setShowScrollDownButton] = useState<boolean>(true);
    const [abortController, setAbortController] = useState<AbortController>(new AbortController());

    const messagesEndRef = useRef<HTMLDivElement>(null);
    const chatContainerRef = useRef<HTMLDivElement>(null);
    const textareaRef = useRef<HTMLTextAreaElement>(null);

    const { getApiUserChat, updateApiUserChatAndGetStream, updateApiUserChatTitle, updateApiUserChat } = useApi();

    const fetchChat = async () => {
        if (selectedChat) {
            setLoadingChat(true);
            const chat = await getApiUserChat(selectedChat.id);
            setCurrentChat(chat);
            setLoadingChat(false);
            homeDispatch({ field: "chatHasFiles", value: chat.file_indexes.length > 0 });
        }
    };

    useEffect(() => {
        fetchChat();
    }, [selectedChat]);

    const handleSend = useCallback(
        async (message: ChatMessage, deleteCount = 0, abortController: AbortController) => {
            if (currentChat) {
                let updatedChat: ChatResponse;

                if (deleteCount) {
                    const updatedMessages = [...currentChat.messages];

                    for (let i = 0; i < deleteCount; i++) {
                        updatedMessages.pop();
                    }

                    updatedChat = {
                        ...currentChat,
                        messages: [...updatedMessages, message]
                    };
                } else {
                    updatedChat = {
                        ...currentChat,
                        messages: [...currentChat.messages, message]
                    };
                }

                setCurrentChat(updatedChat);

                homeDispatch({ field: "loading", value: true });
                homeDispatch({ field: "messageIsStreaming", value: true });

                if (updatedChat.title === "New Chat" && updatedChat.messages.length === 1) {
                    const { content } = message;
                    const customName = content.length > 30 ? content.substring(0, 30) + "..." : content;

                    updatedChat = {
                        ...updatedChat,
                        title: customName
                    };

                    setCurrentChat(updatedChat);

                    await updateApiUserChatTitle(currentChat.id, customName);

                    const updatedChats = chats.map(chat => {
                        if (chat.id === currentChat.id) {
                            return {
                                ...chat,
                                title: customName
                            };
                        }
                        return chat;
                    });

                    homeDispatch({ field: "chats", value: updatedChats });
                }

                const updateChatRequest: UpdateChatRequest = {
                    messages: updatedChat.messages,
                    title: updatedChat.title,
                    persona_id: message.persona?.id
                };

                // THIS IS WHERE THE REQUEST IS SENT TO THE API
                try {
                    const response = await updateApiUserChatAndGetStream(currentChat.id, updateChatRequest);

                    if (!response || !response.ok) {
                        homeDispatch({ field: "loading", value: false });
                        homeDispatch({ field: "messageIsStreaming", value: false });
                        return;
                    }

                    const data = response.body;

                    if (!data) {
                        homeDispatch({ field: "loading", value: false });
                        homeDispatch({ field: "messageIsStreaming", value: false });
                        return;
                    }

                    if (updatedChat.messages.length === 1) {
                        const { content } = message;
                        const customName = content.length > 30 ? content.substring(0, 30) + "..." : content;
                        updatedChat = {
                            ...updatedChat,
                            title: customName
                        };
                    }

                    const reader = data.getReader();
                    const decoder = new TextDecoder();
                    let done = false;
                    let isFirst = true;
                    let text = "";

                    while (!done) {
                        if (stopConversationRef.current === true) {
                            done = true;
                            break;
                        }
                        const { value, done: doneReading } = await reader.read();
                        done = doneReading;
                        const chunkValue = decoder.decode(value);
                        text += chunkValue;

                        if (isFirst) {
                            homeDispatch({ field: "loading", value: false });

                            isFirst = false;

                            const updatedMessages: ChatMessage[] = [
                                ...updatedChat.messages,
                                { role: "assistant", content: chunkValue, persona: message.persona, created_at: new Date() }
                            ];

                            updatedChat = {
                                ...updatedChat,
                                messages: updatedMessages
                            };

                            setCurrentChat(updatedChat);
                        } else {
                            const updatedMessages: ChatMessage[] = updatedChat.messages.map((message, index) => {
                                if (index === updatedChat.messages.length - 1) {
                                    return {
                                        ...message,
                                        content: text
                                    };
                                }
                                return message;
                            });

                            updatedChat = {
                                ...updatedChat,
                                messages: updatedMessages
                            };

                            setCurrentChat(updatedChat);
                        }
                    }

                    homeDispatch({ field: "messageIsStreaming", value: false });
                } catch (e) {
                    if (abortController.signal.aborted) {
                        console.log("aborted");
                    }

                    if ((e as Error).message.includes("Maximum number of chat tokens reached")) {
                        toast.error("Maximum number of chat tokens reached.  Please contact Sundown to extend your quota.", { duration: 10000 });
                    }

                    homeDispatch({ field: "loading", value: false });
                    homeDispatch({ field: "messageIsStreaming", value: false });
                }
            }
        },
        [apiKey, chats, selectedChat, currentChat, stopConversationRef]
    );

    const scrollToBottom = useCallback(() => {
        if (autoScrollEnabled) {
            messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
        }
    }, [autoScrollEnabled]);

    const handleScroll = () => {
        if (chatContainerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = chatContainerRef.current;
            const bottomTolerance = 30;

            if (scrollTop + clientHeight < scrollHeight - bottomTolerance) {
                setAutoScrollEnabled(false);
                setShowScrollDownButton(true);
            } else {
                if (slim != true) {
                    setAutoScrollEnabled(true);
                    setShowScrollDownButton(false);
                } else {
                    setAutoScrollEnabled(false);
                    setShowScrollDownButton(true);
                }
            }
        }
    };

    const handleMessageDelete = async (message: ChatMessage) => {
        if (currentChat) {
            // find the message in the chat
            const { messages } = currentChat;
            const findIndex = messages.findIndex((elm: any) => elm === message);

            // if the message is not found, return
            if (findIndex < 0) return;

            if (findIndex < messages.length - 1 && messages[findIndex + 1].role === "assistant") {
                messages.splice(findIndex, 2);
            } else {
                messages.splice(findIndex, 1);
            }
            const updatedChat = {
                ...currentChat,
                messages
            };

            const updateChatRequest: UpdateChatRequest = {
                messages: updatedChat.messages,
                title: updatedChat.title,
                persona_id: undefined
            };

            await updateApiUserChat(updatedChat.id, updateChatRequest);
            setCurrentChat(updatedChat);
        }

        // TODO: DELETE ON BACKEND - propagate up to parent?
    };

    const handleScrollDown = () => {
        chatContainerRef.current?.scrollTo({
            top: chatContainerRef.current.scrollHeight,
            behavior: "smooth"
        });
    };

    const handleSettings = () => {
        setShowSettings(!showSettings);
    };

    const onClearAll = async () => {
        if (confirm("Are you sure you want to clear all messages?") && selectedChat) {
            console.info("Clear chat!")
            setLoadingChat(true);
            await handleUpdateChat(selectedChat.id, {
                key: "messages",
                value: []
            });
            const chat = await getApiUserChat(selectedChat.id);
            setCurrentChat(chat);
            setLoadingChat(false);
        }

        function timeout(delay: number) {
            return new Promise(res => setTimeout(res, delay));
        }
    };

    const scrollDown = () => {
        if (autoScrollEnabled) {
            messagesEndRef.current?.scrollIntoView(true);
        }
    };
    const throttledScrollDown = throttle(scrollDown, 250);

    useEffect(() => {
        if (slim !== true) {
            throttledScrollDown();
            currentChat && setCurrentMessage(currentChat.messages[currentChat.messages.length - 2]);
        } else {
            return;
        }
    }, [currentChat, throttledScrollDown]);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                setAutoScrollEnabled(entry.isIntersecting);
                if (entry.isIntersecting) {
                    textareaRef.current?.focus();
                }
            },
            {
                root: null,
                threshold: 0.5
            }
        );
        const messagesEndElement = messagesEndRef.current;
        if (messagesEndElement) {
            observer.observe(messagesEndElement);
        }
        return () => {
            if (messagesEndElement) {
                observer.unobserve(messagesEndElement);
            }
        };
    }, [messagesEndRef]);

    return (
        <div className="relative flex-1 overflow-y-auto bg-white dark:bg-[#343541]">
            <>
                <div className="max-h-full overflow-x-hidden" ref={chatContainerRef} onScroll={handleScroll}>
                    {loadingChat ? (
                        <div className="mx-auto flex flex-col space-y-5 md:space-y-10 px-3 pt-5 md:pt-12 sm:max-w-[600px]">
                            <div className="text-center text-xl font-semibold text-gray-800 dark:text-gray-400">Loading chat...</div>
                        </div>
                    ) : currentChat?.messages.length === 0 ? (
                        <>
                            <div className="mx-auto flex flex-col space-y-5 md:space-y-10 px-3 pt-5 md:pt-12 sm:max-w-[600px]">
                                <div className="text-center text-xl font-semibold text-gray-800 dark:text-gray-400">Welcome to {assistantName ?? 'Shyrka'}. Ask me a question!</div>
                            </div>
                        </>
                    ) : (
                        <>
                            {currentChat?.messages.map((message: ChatMessage, index: any) => (
                                <MemoizedChatMessage
                                    key={index}
                                    message={message}
                                    messageIndex={index}
                                    chat={currentChat}
                                    onEdit={editedMessage => {
                                        setCurrentMessage(editedMessage);
                                        // discard edited message and the ones that come after then resend
                                        handleSend(editedMessage, currentChat?.messages.length - index, abortController);
                                    }}
                                    onDelete={handleMessageDelete}
                                />
                            ))}

                            {loading && <ChatLoader />}

                            <div className="h-[162px] bg-white dark:bg-[#343541]" ref={messagesEndRef} />
                        </>
                    )}
                </div>

                <ChatInput
                    slim={slim}
                    deleteAll={onClearAll}
                    defaultPersona={defaultPersona}
                    stopConversationRef={stopConversationRef}
                    textareaRef={textareaRef}
                    onSend={(message, plugin) => {
                        setCurrentMessage(message);
                        handleSend(message, 0, abortController);
                    }}
                    onScrollDownClick={handleScrollDown}
                    onRegenerate={(persona: Persona) => {
                        if (currentMessage) {
                            currentMessage.persona = persona;
                            handleSend(currentMessage, 2, abortController);
                        }
                    }}
                    messageCount={currentChat?.messages.length || 0}
                    onCancel={() => {
                        console.log("Cancelling request");
                        abortController.abort();
                        setAbortController(new AbortController());
                    }}
                    showScrollDownButton={showScrollDownButton}
                />
            </>
        </div>
    );
});
Chat.displayName = "Chat";
