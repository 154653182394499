import { Dispatch, createContext } from 'react';

import { ActionType } from '../../hooks/useCreateReducer';

import { Conversation } from '../../types/chat';
import { KeyValuePair } from '../../types/data';
import { FolderType } from '../../types/folder';

import { ChatInitialState } from './Chat.state';
import { ChatResponse, ChatSummary } from '../../api/chat';

export interface HomeContextProps {
    state: ChatInitialState;
    dispatch: Dispatch<ActionType<ChatInitialState>>;
    handleNewChat: () => void;
    handleCreateFolder: (name: string, type: FolderType) => void;
    handleDeleteFolder: (folderId: string) => void;
    handleUpdateFolder: (folderId: string, name: string) => void;
    handleSelectChat: (chat: ChatSummary) => void;
    handleUpdateChat: (
        chatId: string,
        data: KeyValuePair,
    ) => void;
}

const HomeContext = createContext<HomeContextProps>(undefined!);

export default HomeContext;
