export interface EntraGroup {
    id: string;
    display_name: string;
}

type CallApiFunction = (method: string, path: string, data: object | null, abortController: AbortController) => Promise<Response | null>;

export const searchEntraGroups = async (callApi: CallApiFunction, searchTerm: string): Promise<EntraGroup[]> => {
    const path = `/api/v1/groups/entra/search?search=${searchTerm}`;
    const response = await callApi("GET", path, null, new AbortController());

    if (!response || !response.ok) {
        throw new Error("Failed to search for users");
    }

    const groups = await response.json() as EntraGroup[];
    return groups;
}



