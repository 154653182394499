import { ChatSummary } from '../../api/chat';
import { Conversation } from '../../types/chat';

export interface ChatbarInitialState {
  searchTerm: string;
  filteredConversations: Conversation[];
  filteredChats: ChatSummary[];
}

export const initialState: ChatbarInitialState = {
  searchTerm: '',
  filteredConversations: [],
  filteredChats: [],
};
