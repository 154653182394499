import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useApi } from '../../../hooks/useApi';
import { ExtendedUserResponse, UserResponse } from '../../../api/users';
import { ExtendedPersona, Persona } from '../../../api/personas';
import { UserPersonaModal } from './UserPersonaModal';
import { ThreeDots } from 'react-loader-spinner';
import { AddUserModal } from './AddUserModal';
import { IconArrowBack, IconArrowLeft, IconPlus } from '@tabler/icons-react';



const UserManagement = () => {
  const [allUsers, setAllUsers] = useState<ExtendedUserResponse[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [isPersonaManageModalOpen, setIsModalOpen] = useState(false);
  const [isAddUserModelOpen, setIsAddUserModelOpen] = useState(false);
  const [allPersonas, setAllPersonas] = useState<ExtendedPersona[]>([]);
  const [selectedUser, setSelectedUser] = useState<ExtendedUserResponse | null>(null);
  const { getApiAllUsers, getApiAllPersonas, getApiUserById } = useApi();
  const [filteredUsers, setFilteredUsers] = useState<ExtendedUserResponse[]>([]);

  useEffect(() => {
    setIsLoading(true);

    Promise.all([getApiAllUsers(), getApiAllPersonas()])
      .then(([fetchedUsers, fetchedPersonas]) => {
        setAllUsers(fetchedUsers);
        setAllPersonas(fetchedPersonas);
      })
      .catch(err => {
        setError(err.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [getApiAllUsers, getApiAllPersonas]);

  useEffect(() => {
    setFilteredUsers(allUsers.filter(user =>
      user.email.toLowerCase().includes(searchTerm.toLowerCase())
    ));
  }, [allUsers, searchTerm])

  const handleSearchChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
    setSearchTerm(event.target.value);
  };

  const handleManageClick = (user: ExtendedUserResponse) => {
    if (user && user.personas) {
      setSelectedUser(user);
      setIsModalOpen(true);
    } else {
      console.error("User or personas data is missing or undefined.");
    }
  };

  const handleModalClose = async () => {
    setIsModalOpen(false);

    if (selectedUser) {
      const updatedUser = await getApiUserById(selectedUser.user_id);

      const updatedAllUsers = allUsers.map(user => {
        if (user.user_id === updatedUser.user_id) {
          return updatedUser;
        }
        return user;
      });

      setAllUsers(updatedAllUsers);
      setSelectedUser(null);
    }
  };

  const handleAddUserModalClose = async (addedUsers: ExtendedUserResponse[]) => {
    setIsAddUserModelOpen(false);
    setAllUsers([...allUsers, ...addedUsers]);
  }

  const navigate = useNavigate();

  const handleBackpageClick = () => {
    navigate(`/admin/adminhome`);
  };

  const handleAddUserButtonClick = () => {
    setIsAddUserModelOpen(true);
  }


  if (isLoading) return (
    <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col justify-center items-center">
      <div className="text-xl font-semibold text-gray-800 dark:text-gray-400">
        Loading Users
      </div>
      <ThreeDots color="#202123" height={110} width={110} wrapperClass='flex justify-center items-center' />
    </div>
  );


  if (error) return <div>Error: {error}</div>;

  return (
    <>
      <main className="flex flex-grow h-[calc(100vh-64px)] overflow-y-auto w-screen flex-col text-sm text-white dark:text-white bg-customGrey">
        <div className="flex items-center justify-between p-4 h-16 mt-2" >
          <div className="flex items-center space-x-4">

            <button className="left-5 w-24 text-2xl max-h-8 bg-blueButton text-white rounded px-2 focus:outline-none focus:ring-2 focus:ring-blue-300 z-10 flex items-center justify-center"
              onClick={handleBackpageClick}>
              <span>&#8592;</span>
            </button>

            <button
              className="w-28 max-h-8 bg-blueButton text-white rounded py-1 px-2 focus:outline-none focus:ring-2 focus:ring-blue-300 flex items-center justify-center"
              onClick={handleAddUserButtonClick}
            >
              <IconPlus className="mr-1" /> <span>Add User</span>
            </button>
          </div>

          <div className="flex pr-4 w-1/3">
            <input
              type="text"
              placeholder="Search..."
              value={searchTerm}
              onChange={handleSearchChange}
              className="form-input text-base block w-full px-4 py-2 text-gray-700 bg-white border border-solid border-gray-300 rounded-lg transition ease-in-out focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
            // style={{ maxWidth: '400px' }}
            />
          </div>

        </div>

        <div className="bg-[#1b1c20] p-4 rounded-2xl mx-8 my-4">
          <div className="flex justify-center">
            <div className="w-full overflow-x-auto">
              <div className="align-middle inline-block min-w-full">
                <div className="shadow overflow-hidden rounded-2xl">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-white">
                      <tr>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">User</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Assigned Personas</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Manage Personas</th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {filteredUsers.map((user) => (
                        <tr key={user.user_id} className="bg-white">
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{user.email}</td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{user.personas.map(p => p.name).join(', ')}</td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                            <button
                              className="px-4 py-2 rounded-md text-sm font-medium bg-blue-500 text-white" onClick={() => handleManageClick(user)}
                            >
                              Manage
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      <UserPersonaModal user={selectedUser} allPersonas={allPersonas} isOpen={isPersonaManageModalOpen} onClose={handleModalClose}></UserPersonaModal>
      <AddUserModal allUserIds={allUsers.map(user => user.user_id)} defaultPersonas={allPersonas.filter(p => p.is_default)} isOpen={isAddUserModelOpen} onClose={handleAddUserModalClose}></AddUserModal>
    </>
  );
};

export default UserManagement;