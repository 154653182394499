import {
    IconArrowDown,
    IconBolt,
    IconBrandGoogle,
    IconFile,
    IconLoader,
    IconLoader2,
    IconPlayerStop,
    IconRepeat,
    IconSend,
    IconTrash
} from "@tabler/icons-react";
import { KeyboardEvent, MutableRefObject, useCallback, useContext, useEffect, useRef, useState } from "react";

import { Message } from "../../types/chat";
import { Prompt } from "../../types/prompt";

import HomeContext from "../../pages/chatv2/Chatv2.context";

import { PromptList } from "./PromptList";
import { VariableModal } from "./VariableModal";
import shyrkaLogo from "../../assets/ProjectShyrka_Icon.png";
import { ChatMessage } from "../../api/chat";
import { PromptResponse } from "../../api/prompts";
import { Spinner } from "@fluentui/react";
import { Persona } from "../../api/users";
import { ChatFilesDialog } from "../Files/ChatFilesDialog";

interface Props {
    onSend: (message: ChatMessage, plugin: Plugin | null) => void;
    onRegenerate: (persona: Persona) => void;
    onCancel: () => void;
    onScrollDownClick: () => void;
    deleteAll: () => void;
    stopConversationRef: MutableRefObject<boolean>;
    textareaRef: MutableRefObject<HTMLTextAreaElement | null>;
    showScrollDownButton: boolean;
    messageCount: number;
    slim: boolean;
    defaultPersona: string;
}

export const ChatInput = ({
    onSend,
    onRegenerate,
    onCancel,
    onScrollDownClick,
    deleteAll,
    stopConversationRef,
    textareaRef,
    showScrollDownButton,
    messageCount,
    slim,
    defaultPersona
}: Props) => {
    const {
        state: { selectedChatSummary: selectedChat, messageIsStreaming, userPrompts, sharedPrompts, loggedOnUser, chatHasFiles },
        dispatch: homeDispatch
    } = useContext(HomeContext);

    const getDefaultPersonaId = () => {
        if (loggedOnUser && loggedOnUser.personas.length > 0) {
            let personaIndex = loggedOnUser.personas
                .map(function (e) {
                    return e.name;
                })
                .indexOf(defaultPersona);
            if (personaIndex == -1) {
                console.warn(`Persona '${defaultPersona}' not found in list, defaulting to '${loggedOnUser.personas[0].name}'`, loggedOnUser.personas)
                personaIndex = 0;
            }

            return loggedOnUser.personas[personaIndex].id;
        }
        return "";
    };

    const [content, setContent] = useState<string>();
    const [isTyping, setIsTyping] = useState<boolean>(false);
    const [showPromptList, setShowPromptList] = useState(false);
    const [activePromptIndex, setActivePromptIndex] = useState(0);
    const [promptInputValue, setPromptInputValue] = useState("");
    const [variables, setVariables] = useState<string[]>([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [showPluginSelect, setShowPluginSelect] = useState(false);
    const [plugin, setPlugin] = useState<Plugin | null>(null);
    const [allPrompts, setAllPrompts] = useState<PromptResponse[]>([]);
    const [assignedPersona, setAssignedPersona] = useState<Persona[]>([]);
    const [selectedPersonaId, setSelectedPersonaId] = useState<string>(getDefaultPersonaId());
    const [isChatFileDialogOpen, setIsChatFileDialog] = useState<boolean>(false);

    const promptListRef = useRef<HTMLUListElement | null>(null);

    useEffect(() => {
        setAllPrompts([...userPrompts, ...sharedPrompts]);
    }, [userPrompts, sharedPrompts]);

    useEffect(() => {
        if (loggedOnUser) {
            setAssignedPersona(loggedOnUser.personas);
            setSelectedPersonaId(getDefaultPersonaId());
        }
    }, [loggedOnUser]);

    const filteredPrompts = allPrompts.filter(prompt => prompt.name.toLowerCase().includes(promptInputValue.toLowerCase()));

    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const value = e.target.value;
        const maxLength = undefined; //selectedConversation?.model.maxLength;

        if (maxLength && value.length > maxLength) {
            alert(`Message limit is ${maxLength} characters. You have entered ${value.length} characters.`);
            return;
        }

        setContent(value);
        updatePromptListVisibility(value);
    };

    const handlePersonaSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const personaId = e.target.value;
        setSelectedPersonaId(personaId);
        homeDispatch({ field: "selectedPersonaId", value: personaId })
    }

    const handleSend = () => {
        if (messageIsStreaming) {
            return;
        }

        if (!content) {
            alert("Please enter a message");
            return;
        }

        const persona = loggedOnUser?.personas.find(p => p.id === selectedPersonaId);
        onSend({ role: "user", content, persona: persona, created_at: new Date() }, plugin);
        setContent("");
        setPlugin(null);

        if (window.innerWidth < 640 && textareaRef && textareaRef.current) {
            textareaRef.current.blur();
        }
    };

    const handleStopConversation = () => {
        onCancel();
        stopConversationRef.current = true;
        setTimeout(() => {
            stopConversationRef.current = false;
        }, 1000);
    };

    const isMobile = () => {
        const userAgent = typeof window.navigator === "undefined" ? "" : navigator.userAgent;
        const mobileRegex = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i;
        return mobileRegex.test(userAgent);
    };

    const handleInitModal = () => {
        const selectedPrompt = filteredPrompts[activePromptIndex];
        if (selectedPrompt) {
            setContent(prevContent => {
                const newContent = prevContent?.replace(/\/\w*$/, selectedPrompt.content);
                return newContent;
            });
            handlePromptSelect(selectedPrompt);
        }
        setShowPromptList(false);
    };

    const handleKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
        if (showPromptList) {
            if (e.key === "ArrowDown") {
                e.preventDefault();
                setActivePromptIndex(prevIndex => (prevIndex < allPrompts.length - 1 ? prevIndex + 1 : prevIndex));
            } else if (e.key === "ArrowUp") {
                e.preventDefault();
                setActivePromptIndex(prevIndex => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
            } else if (e.key === "Tab") {
                e.preventDefault();
                setActivePromptIndex(prevIndex => (prevIndex < allPrompts.length - 1 ? prevIndex + 1 : 0));
            } else if (e.key === "Enter") {
                e.preventDefault();
                handleInitModal();
            } else if (e.key === "Escape") {
                e.preventDefault();
                setShowPromptList(false);
            } else {
                setActivePromptIndex(0);
            }
        } else if (e.key === "Enter" && !isTyping && !isMobile() && !e.shiftKey) {
            e.preventDefault();
            handleSend();
        } else if (e.key === "/" && e.metaKey) {
            e.preventDefault();
            setShowPluginSelect(!showPluginSelect);
        }
    };

    const parseVariables = (content: string) => {
        const regex = /{{(.*?)}}/g;
        const foundVariables = [];
        let match;

        while ((match = regex.exec(content)) !== null) {
            foundVariables.push(match[1]);
        }

        return foundVariables;
    };

    const updatePromptListVisibility = useCallback((text: string) => {
        const match = text.match(/\/\w*$/);

        if (match) {
            setShowPromptList(true);
            setPromptInputValue(match[0].slice(1));
        } else {
            setShowPromptList(false);
            setPromptInputValue("");
        }
    }, []);

    const handlePromptSelect = (userPrompt: PromptResponse) => {
        const parsedVariables = parseVariables(userPrompt.content);
        setVariables(parsedVariables);

        if (parsedVariables.length > 0) {
            setIsModalVisible(true);
        } else {
            setContent(prevContent => {
                const updatedContent = prevContent?.replace(/\/\w*$/, userPrompt.content);
                return updatedContent;
            });
            updatePromptListVisibility(userPrompt.content);
        }
    };

    const handleSubmit = (updatedVariables: string[]) => {
        const newContent = content?.replace(/{{(.*?)}}/g, (match, variable) => {
            const index = variables.indexOf(variable);
            return updatedVariables[index];
        });

        setContent(newContent);

        if (textareaRef && textareaRef.current) {
            //textareaRef.current.focus();
        }
    };

    const handleRegenerate = () => {
        const persona = loggedOnUser?.personas.find(p => p.id === selectedPersonaId);
        onRegenerate(persona!);
    };
    const getPersonaName = (personaId: string) => {
        if (loggedOnUser && loggedOnUser.personas.length > 0) {
            let personaIndex = loggedOnUser.personas
                .map(function (e) {
                    return e.id;
                })
                .indexOf(personaId);
            return loggedOnUser.personas[personaIndex].name;
        } else {
            return "";
        }
    };
    useEffect(() => {
        if (promptListRef.current) {
            promptListRef.current.scrollTop = activePromptIndex * 30;
        }
    }, [activePromptIndex]);

    useEffect(() => {
        if (textareaRef && textareaRef.current) {
            textareaRef.current.style.height = "inherit";
            textareaRef.current.style.height = `${textareaRef.current?.scrollHeight}px`;
            textareaRef.current.style.overflow = `${textareaRef?.current?.scrollHeight > 400 ? "auto" : "hidden"}`;
        }
    }, [content]);

    useEffect(() => {
        const handleOutsideClick = (e: MouseEvent) => {
            if (promptListRef.current && !promptListRef.current.contains(e.target as Node)) {
                setShowPromptList(false);
            }
        };

        window.addEventListener("click", handleOutsideClick);

        return () => {
            window.removeEventListener("click", handleOutsideClick);
        };
    }, []);

    return (
        <div className="absolute md:pb-0 pb-2 md:mb-0 mb-0 bottom-0 left-0 right-0 w-full border-transparent bg-gradient-to-b from-transparent via-white to-white dark:border-white/20 dark:via-[#343541] dark:to-[#343541] md:pt-2">
            <div className="stretch mx-2 mt-12 flex flex-col gap-1 last:mb-2 md:mx-4 md:mt-[52px] md:last:mb-6 lg:mx-auto lg:max-w-3xl px-3">
                <div className="relative flex w-full flex-grow flex-col items">
                    <div className="flex items-center mx-auto mb-1">
                        {messageIsStreaming && (
                            <button
                                className="flex w-fit items-center gap-3 rounded border border-neutral-200 bg-white py-2 px-4 text-black hover:opacity-50 dark:border-neutral-600 dark:bg-[#343541] dark:text-white"
                                disabled={true}
                            >
                                <Spinner /> {"Generating..."}
                            </button>
                        )}

                        {!messageIsStreaming && selectedChat && messageCount > 0 && (
                            <button
                                className="flex w-fit items-center gap-3 rounded border border-neutral-200 bg-white py-2 px-4 text-black hover:opacity-50 dark:border-neutral-600 dark:bg-[#343541] dark:text-white"
                                onClick={handleRegenerate}
                            >
                                <IconRepeat size={16} /> {"Regenerate response"}
                            </button>
                        )}
                    </div>

                    {slim === true ? (
                        <div className={"flex items-center mx-10 ml-auto mb-1 invisible"}>
                            <label className="p-1 text-neutral-800 opacity-60">
                                Using persona: {getPersonaName(selectedPersonaId)}
                            </label>
                        </div>
                    ) : (
                        <div className={"flex items-center sm:max-lg:m-auto lg:ml-auto mb-1 " + (getDefaultPersonaId() === "" ? "invisible" : "visible")}>
                            <label htmlFor="persona-select" className="mr-2 text-black dark:text-white">
                                Select a persona:
                            </label>
                            <select
                                id="persona-select"
                                value={selectedPersonaId}
                                disabled={chatHasFiles}
                                onChange={handlePersonaSelect}
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md block p-1 dark:border-gray-900/50 dark:bg-[#40414F] dark:text-white dark:placeholder-gray-400 dark:text-white">
                                {assignedPersona.map(persona => (
                                    <option key={persona.id} value={persona.id}>
                                        {chatHasFiles ? "Using chat files" : persona.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                    )}


                </div>

                <div className="relative flex w-full flex-grow flex-col rounded-md border border-black/10 bg-red shadow-[0_0_10px_rgba(0,0,0,0.10)] dark:border-gray-900/50 dark:bg-[#40414F] dark:text-white dark:shadow-[0_0_15px_rgba(0,0,0,0.10)]">
                    {slim && (
                        <div className="absolute bottom-12 left-0 lg:bottom-0 lg:-left-10">
                            <button
                                className="flex h-7 w-7 items-center justify-center rounded-full bg-black text-white-800 shadow-md hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-blue-500 dark:bg-gray-700 dark:text-neutral-200 hover:bg-white hover:text-black hover:border hover:border-black"
                                onClick={deleteAll}
                            >
                                <IconTrash size={18} />
                            </button>
                        </div>
                    )}
                    <textarea
                        ref={textareaRef}
                        className="m-0 w-full resize-none border-0 bg-transparent p-0 py-2 pr-8 pl-8 text-black dark:bg-transparent dark:text-white md:py-3 md:pl-5"
                        style={{
                            resize: "none",
                            bottom: `${textareaRef?.current?.scrollHeight}px`,
                            maxHeight: "400px",
                            overflow: `${textareaRef.current && textareaRef.current.scrollHeight > 400 ? "auto" : "hidden"}`
                        }}
                        placeholder={'Type a message or type "/" to select a prompt...'}
                        value={content}
                        rows={1}
                        onCompositionStart={() => setIsTyping(true)}
                        onCompositionEnd={() => setIsTyping(false)}
                        onChange={handleChange}
                        onKeyDown={handleKeyDown}
                    />
                    <button
                        className={`absolute right-2 top-2 rounded-sm p-1 text-neutral-800 opacity-60  ${!messageIsStreaming
                            ? "hover:text-neutral-900 hover:bg-neutral-200 dark:bg-opacity-50 dark:text-neutral-100 dark:hover:text-neutral-200"
                            : ""
                            }`}
                        onClick={handleSend}
                        disabled={messageIsStreaming}
                    >
                        <IconSend size={18} />
                    </button>
                    <div className="absolute flex lg:flex-col-reverse xs:flex-row bottom-12 right-0 lg:bottom-0 lg:-right-10 gap-1">
                        {slim && (
                            <div className={"flex items-center w-full flex-col ml-auto"}>
                                <button
                                    className="flex h-7 w-7 items-center justify-center rounded-full bg-neutral-300 text-gray-800 shadow-md hover:shadow-2xl hover:bg-white hover:border hover:border-neutral-300 focus:outline-none focus:ring-2 focus:ring-blue-500 dark:bg-gray-700 dark:text-neutral-200"
                                    onClick={() => setIsChatFileDialog(true)}
                                >
                                    <IconFile size={18} />
                                </button>
                                <ChatFilesDialog
                                    open={isChatFileDialogOpen}
                                    onClose={() => {
                                        setIsChatFileDialog(false);
                                    }}
                                />
                            </div>
                        )}
                        {showScrollDownButton && (
                            <div className={"flex items-center w-full flex-col ml-auto"}>
                                <button
                                    className="flex h-7 w-7 items-center justify-center rounded-full bg-neutral-300 text-gray-800 shadow-md hover:shadow-2xl hover:bg-white hover:border hover:border-neutral-300 focus:outline-none focus:ring-2 focus:ring-blue-500 dark:bg-gray-700 dark:text-neutral-200"
                                    onClick={onScrollDownClick}
                                >
                                    <IconArrowDown size={18} />
                                </button>
                            </div>
                        )}
                    </div>
                    {showPromptList && filteredPrompts.length > 0 && (
                        <div className="absolute bottom-12 w-full">
                            <PromptList
                                activePromptIndex={activePromptIndex}
                                userPrompts={filteredPrompts}
                                onSelect={handleInitModal}
                                onMouseOver={setActivePromptIndex}
                                promptListRef={promptListRef}
                            />
                        </div>
                    )}

                    {isModalVisible && (
                        <VariableModal
                            userPrompt={filteredPrompts[activePromptIndex]}
                            variables={variables}
                            onSubmit={handleSubmit}
                            onClose={() => setIsModalVisible(false)}
                        />
                    )}
                </div>
            </div>
        </div>
        // </div >
    );
};
