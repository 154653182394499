import { useState, useEffect } from 'react';
import { SharepointTargets } from '../../../api/personas';
import FolderSelectionModal from './FolderSelectionModal';
import { Tooltip } from 'react-tooltip';
import '../../../styles/globals.css';
import { IconAlertCircle } from '@tabler/icons-react';
import internal from 'stream';



function SharepointTargetsModal(props: any) {
    const [showFolderSelectionModal, setShowFolderSelectionModal] = useState(false);
    const [siteIndex, setSiteIndex] = useState(0);
    const [folderType, setFolderType] = useState('');
    const [sitePathValidationErrors, setSitePathValidationError] = useState<Map<number, string>>(new Map())

    // Initialize state with either the provided SharePointTargets or a default structure
    const [sharepointTargets, setSharepointTargets] = useState<SharepointTargets[]>(
        props.SharepointTargets.length > 0 ? props.SharepointTargets : [{
            sharepoint_hostname: "",
            sites: [],
        }]
    );

    useEffect(() => {
        if (props.show) {
            for (const target of sharepointTargets) {
                for (const site of target.sites) {
                    if (!(site.site_path.startsWith("/sites"))) {
                        let errs = sitePathValidationErrors;
                        errs.set(target.sites.indexOf(site), "Site path must start with '/sites'");
                        setSitePathValidationError(errs);
                    }
                }
            }
        }

    }, [props.show])

    useEffect(() => {
        // If props.SharepointTargets is empty, initialize with a default structure
        setSharepointTargets(props.SharepointTargets.length > 0 ? props.SharepointTargets : [{
            sharepoint_hostname: "",
            sites: [],
        }]);
    }, [props.SharepointTargets]);

    // Ensure that rendering occurs after state is updated
    if (!sharepointTargets || sharepointTargets.length === 0) {
        return null; // or render a loading indicator, message, etc.
    }

    const handleHostnameChange = (targetIndex: number, value: string) => {
        // Regular expression to match double quotes and single quotes
        const invalidChars = /["']/g;

        // Replace invalid characters with an empty string
        const sanitizedValue = value.replace(invalidChars, '');

        setSharepointTargets((prevTargets) => {
            const updatedTargets = [...prevTargets];
            updatedTargets[targetIndex].sharepoint_hostname = sanitizedValue;
            // Call the callback function with the updated targets
            props.onTargetsChange(updatedTargets);
            return updatedTargets;
        });
    };

    const handleDeleteTarget = (targetIndex: number, siteIndex: number) => {
        setSharepointTargets((prevTargets) => {
            const updatedTargets = prevTargets.map((target, index) => {
                if (index === targetIndex) {
                    // Remove the site at siteIndex from the current target's sites array
                    const updatedSites = target.sites.filter((_, i) => i !== siteIndex);
                    return { ...target, sites: updatedSites };
                }
                return target;
            });

            // Call the callback function with the updated targets
            props.onTargetsChange(updatedTargets);

            return updatedTargets;
        });
    };

    const handleIncludeSitePagesChange = (targetIndex: number, include: boolean) => {
        setSharepointTargets(prevTargets => {
            const updatedTargets = [...prevTargets];
            updatedTargets[targetIndex].sites.forEach(site => {
                site.include_site_pages = include;
            });
            // Call the callback function with the updated targets
            props.onTargetsChange(updatedTargets);
            return updatedTargets;
        });
    };

    const addNewSite = () => {
        const newSite = {
            site_path: "/sites/your-site-path",
            included_folders: [],
            excluded_folders: [],
            include_site_pages: false
        };

        setSharepointTargets(prevTargets => {
            // If there are existing targets, add the new site to the first target.
            if (prevTargets.length > 0) {
                const updatedTargets = [...prevTargets];
                updatedTargets[0].sites.push(newSite); // Update the first target
                return updatedTargets;
            } else {
                // If there are no targets, create a new one with the new site.
                return [{
                    sharepoint_hostname: "",
                    sites: [newSite]
                }];
            }
        });
    };

    const handleSitePathChange = (targetIndex: number, siteIndex: number, value: string, key: 'site_path') => {
        const invalidChars = /["']/g;
        let sanitizedValue = value.replace(invalidChars, '');

        if (sanitizedValue === '') {
            return;
        }

        if (!(sanitizedValue.startsWith('/'))) {
            sanitizedValue = '/' + sanitizedValue;
        }

        if (!(sanitizedValue.startsWith('/sites'))) {
            let errs = sitePathValidationErrors;
            errs.set(siteIndex, "Site path must start with '/sites'");
            setSitePathValidationError(errs);
        } else {
            let errs = sitePathValidationErrors;
            errs.delete(siteIndex);
            setSitePathValidationError(errs);
        }

        // Clone the current state to avoid direct mutation
        const updatedTargets: any = [...sharepointTargets];
        // Update only the site_path of the site
        updatedTargets[targetIndex].sites[siteIndex][key] = sanitizedValue;

        // Update the state with the new targets
        setSharepointTargets(updatedTargets);
        props.onTargetsChange(updatedTargets);
    };

    const handleFolderChange = (newFolders: any, folderType: string, siteIndex: number) => {
        // Clone the current state to avoid direct mutation
        const updatedTargets = [...sharepointTargets];
        // Update the specified folders of the site

        if (folderType === 'Included') {
            updatedTargets[0].sites[siteIndex].included_folders = newFolders;
        } else if (folderType === 'Excluded') {
            updatedTargets[0].sites[siteIndex].excluded_folders = newFolders;
        }
        // Update the state with the new targets
        setSharepointTargets(updatedTargets);
        props.onTargetsChange(updatedTargets);
        // Close the modal
        setShowFolderSelectionModal(false);
    };

    return (
        <div className={`fixed inset-0 z-50 overflow-auto bg-smokeLight flex items-center justify-center ${props.show ? '' : 'hidden'}`}>
            <div className="relative mx-auto p-5 border shadow-lg rounded-md bg-white w-full max-w-4xl h-4/5 max-h-full flex flex-col">
                <div className="flex-grow overflow-y-auto p-3">
                    <h3 className="text-lg leading-6 font-medium text-gray-900 text-center">
                        Editing SharePoint Targets For: {props.PersonaName}
                    </h3>
                    <div style={{ color: 'black', textAlign: 'center', fontSize: '15px', marginTop: '5px' }}>
                        Last Updated: {props.formattedDate}
                    </div>

                    {/* Table for SharePoint Targets */}
                    <div className="mt-5">
                        {sharepointTargets.map((target, targetIndex) => (
                            <div key={targetIndex}>
                                <div className="px-6 py-4 whitespace-nowrap">
                                    <div className="flex items-center">
                                        <label htmlFor={`hostname-${targetIndex}`} className="text-md font-medium text-gray-700 mr-2">
                                            SharePoint Hostname:
                                        </label>
                                        <input
                                            type="text"
                                            id={`hostname-${targetIndex}`}
                                            value={target.sharepoint_hostname}
                                            onChange={(e) => handleHostnameChange(targetIndex, e.target.value)}
                                            className="form-input rounded-md shadow-sm border-2 border-gray-400 focus:border-black focus:ring-0 p-1"
                                            placeholder="customer.sharepoint.com"
                                            style={{ color: "black", width: '350px' }}
                                            data-tooltip-id="hostname-tooltip"
                                            data-tooltip-place='bottom'
                                        />
                                        <Tooltip id="hostname-tooltip" className='max-w-[55%]'>
                                            <div >
                                                <span className='whitespace-normal font-large'>
                                                    Enter the hostname of the SharePoint site. For example, if the URL of your SharePoint site is 'https://customer.sharepoint.com/sites/your-site-path', the hostname is 'customer.sharepoint.com'.
                                                </span>
                                            </div>
                                        </Tooltip>
                                    </div>
                                </div>
                                {sharepointTargets.length > 0 ? (
                                    <table className="min-w-full divide-y divide-gray-200 mt-2">
                                        <thead className="bg-gray-50">
                                            <tr>
                                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    Delete
                                                </th>
                                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    Site Path
                                                </th>
                                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    Included Folders
                                                </th>
                                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    Excluded Folders
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="bg-white divide-y divide-gray-200">
                                            {target.sites.map((site, siteIndex) => (
                                                <tr key={siteIndex}>
                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                        <button
                                                            type="button"
                                                            onClick={() => handleDeleteTarget(targetIndex, siteIndex)}
                                                            className="text-red-600 hover:text-red-900"
                                                        >
                                                            Delete
                                                        </button>
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                        <div className="flex items-center space-x-2">
                                                            <input
                                                                type="text"
                                                                required
                                                                value={site.site_path}
                                                                placeholder='/sites/your-site-path'
                                                                onChange={(e) => handleSitePathChange(targetIndex, siteIndex, e.target.value, 'site_path')}
                                                                className="form-input rounded-md shadow-sm mt-1 block p-1 w-full"
                                                                style={{ color: "black" }}
                                                                data-tooltip-id="site-path-tooltip"
                                                                data-tooltip-place='bottom'
                                                            />
                                                            {sitePathValidationErrors.has(siteIndex) && (
                                                                <div>
                                                                    <IconAlertCircle size={16} className="text-red-600" data-tooltip-id="site-path-error" data-tooltip-place='bottom' />
                                                                    <Tooltip id="site-path-error" className='max-w-[55%]'>
                                                                        <div >
                                                                            <span className='whitespace-normal font-large'>
                                                                                {sitePathValidationErrors.get(siteIndex)}
                                                                            </span>
                                                                        </div>
                                                                    </Tooltip>
                                                                </div>
                                                            )}
                                                            <Tooltip id="site-path-tooltip" className='max-w-[55%]'>
                                                                <div >
                                                                    <span className='whitespace-normal font-large'>
                                                                        To find the site path, use the part of the SharePoint site URL that follows the host name. For example, in 'https://yourcompany.sharepoint.com/sites/your-site-path', the site path is '/sites/your-site-path'.
                                                                    </span>
                                                                </div>
                                                            </Tooltip>
                                                        </div>
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                        <button
                                                            type="button"
                                                            onClick={() => {
                                                                setSiteIndex(siteIndex);
                                                                setFolderType('Included');
                                                                setShowFolderSelectionModal(true);
                                                            }}
                                                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                                                        >
                                                            Edit Included Folders
                                                        </button>
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                        <button
                                                            type="button"
                                                            onClick={() => {
                                                                setSiteIndex(siteIndex);
                                                                setFolderType('Excluded');
                                                                setShowFolderSelectionModal(true);
                                                            }}
                                                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                                                        >
                                                            Edit Excluded Folders
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                ) : (
                                    <div className="text-center">
                                        <p>No SharePoint targets found.</p>
                                        <button
                                            type="button"
                                            onClick={() => addNewSite()} // This function should be implemented to handle adding a new target
                                            className="mt-2 inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-500 text-base font-medium text-white hover:bg-green-700 focus:outline-none sm:text-sm"
                                        >
                                            Add New Target
                                        </button>
                                    </div>
                                )}

                                <div className="flex items-center justify-between mt-4">
                                    <div className="flex items-center">
                                        <label htmlFor={`include-site-pages-${targetIndex}`} className="text-md font-medium text-gray-700 mr-2">
                                            Include Site Pages:
                                        </label>
                                        <input
                                            id={`include-site-pages-${targetIndex}`}
                                            type="checkbox"
                                            checked={target.sites.some(site => site.include_site_pages)}
                                            onChange={(e) => handleIncludeSitePagesChange(targetIndex, e.target.checked)}
                                            className="form-checkbox rounded border-gray-400 text-blue-600 focus:border-black focus:ring-0"
                                            style={{ color: "black" }}
                                        />
                                    </div>

                                    <button
                                        type="button"
                                        onClick={addNewSite}
                                        className="inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-500 text-base font-medium text-white hover:bg-green-700 focus:outline-none sm:text-sm"
                                    >
                                        Add Target
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                    {sharepointTargets.length > 0 && sharepointTargets[0].sites.length > 0 && (
                        <FolderSelectionModal
                            show={showFolderSelectionModal}
                            onHide={() => setShowFolderSelectionModal(false)}
                            siteIndex={siteIndex}
                            folderType={folderType}
                            site={sharepointTargets[0].sites[siteIndex]}
                            handleFolderChange={handleFolderChange}
                        />
                    )}
                </div>
                <div className="bg-white border-t">
                    {/* Fixed Action Buttons */}
                    <button type="button" className="mt-4 inline-flex w-full justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-500 text-base font-medium text-white hover:bg-red-700 focus:outline-none sm:text-sm" onClick={props.onHide}>
                        Close Editor
                    </button>
                </div>
            </div>
        </div>
    );
}

export default SharepointTargetsModal;