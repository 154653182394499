import { IconCheck, IconMessage, IconPencil, IconTrash, IconX } from "@tabler/icons-react";
import { DragEvent, KeyboardEvent, MouseEventHandler, useContext, useEffect, useState } from "react";

import { Conversation } from "../../../types/chat";

import HomeContext from "../../../pages/chatv2/Chatv2.context";

import SidebarActionButton from "../../Buttons/SidebarActionButton";
import ChatbarContext from "../Chatbar.context";
import { ChatSummary } from "../../../api/chat";

interface Props {
    chat: ChatSummary;
}

export const ChatComponent = ({ chat: chat }: Props) => {
    const {
        state: { selectedChatSummary: selectedChat, messageIsStreaming },
        handleSelectChat: handleSelectChat,
        handleUpdateChat: handleUpdateChat
    } = useContext(HomeContext);

    const { handleDeleteChat } = useContext(ChatbarContext);

    const [isDeleting, setIsDeleting] = useState(false);
    const [isRenaming, setIsRenaming] = useState(false);
    const [renameValue, setRenameValue] = useState("");

    const handleEnterDown = (e: KeyboardEvent<HTMLDivElement>) => {
        if (e.key === "Enter") {
            e.preventDefault();
            selectedChat && handleRename(selectedChat);
        }
    };

    const handleDragStart = (e: DragEvent<HTMLButtonElement>, chat: ChatSummary) => {
        if (e.dataTransfer) {
            e.dataTransfer.setData("conversation", JSON.stringify(chat));
        }
    };

    const handleRename = (chat: ChatSummary) => {
        // TODO: UPDATE ON BACKEND

        if (renameValue.trim().length > 0) {
            handleUpdateChat(chat.id, {
                key: "title",
                value: renameValue
            });
            setRenameValue("");
            setIsRenaming(false);
        }
    };

    const handleConfirm: MouseEventHandler<HTMLButtonElement> = e => {
        e.stopPropagation();
        if (isDeleting) {
            handleDeleteChat(chat);
        } else if (isRenaming) {
            handleRename(chat);
        }
        setIsDeleting(false);
        setIsRenaming(false);
    };

    const handleCancel: MouseEventHandler<HTMLButtonElement> = e => {
        e.stopPropagation();
        setIsDeleting(false);
        setIsRenaming(false);
    };

    const handleOpenRenameModal: MouseEventHandler<HTMLButtonElement> = e => {
        e.stopPropagation();
        setIsRenaming(true);
        selectedChat && setRenameValue(selectedChat.title);
    };
    const handleOpenDeleteModal: MouseEventHandler<HTMLButtonElement> = e => {
        e.stopPropagation();
        setIsDeleting(true);
    };

    useEffect(() => {
        if (isRenaming) {
            setIsDeleting(false);
        } else if (isDeleting) {
            setIsRenaming(false);
        }
    }, [isRenaming, isDeleting]);

    return (
        <div className="relative flex items-center">
            {isRenaming && selectedChat?.id === chat.id ? (
                <div className="flex w-full items-center gap-3 rounded-lg bg-[#343541]/90 p-3">
                    <IconMessage size={18} />
                    <input
                        className="mr-12 flex-1 overflow-hidden overflow-ellipsis border-neutral-400 bg-transparent text-left text-[12.5px] leading-3 text-white outline-none focus:border-neutral-100"
                        type="text"
                        value={renameValue}
                        onChange={e => setRenameValue(e.target.value)}
                        onKeyDown={handleEnterDown}
                    />
                </div>
            ) : (
                <button
                    className={`flex w-full cursor-pointer items-center gap-3 rounded-lg p-3 text-sm transition-colors duration-200 hover:bg-[#343541]/90 ${
                        messageIsStreaming ? "disabled:cursor-not-allowed" : ""
                    } ${selectedChat?.id === chat.id ? "bg-[#343541]/90" : ""}`}
                    onClick={() => handleSelectChat(chat)}
                    disabled={messageIsStreaming}
                    draggable="true"
                    onDragStart={e => handleDragStart(e, chat)}
                >
                    <IconMessage size={18} />
                    <div
                        className={`relative max-h-5 flex-1 overflow-hidden text-ellipsis whitespace-nowrap break-all text-left text-[12.5px] leading-3 ${
                            selectedChat?.id === chat.id ? "pr-12" : "pr-1"
                        }`}
                    >
                        {chat.title}
                    </div>
                </button>
            )}

            {(isDeleting || isRenaming) && selectedChat?.id === chat.id && (
                <div className="absolute right-1 z-10 flex text-gray-300">
                    <SidebarActionButton handleClick={handleConfirm}>
                        <IconCheck size={18} />
                    </SidebarActionButton>
                    <SidebarActionButton handleClick={handleCancel}>
                        <IconX size={18} />
                    </SidebarActionButton>
                </div>
            )}

            {selectedChat?.id === chat.id && !isDeleting && !isRenaming && (
                <div className="absolute right-1 z-10 flex text-gray-300">
                    <SidebarActionButton handleClick={handleOpenRenameModal}>
                        <IconPencil size={18} />
                    </SidebarActionButton>
                    <SidebarActionButton handleClick={handleOpenDeleteModal}>
                        <IconTrash size={18} />
                    </SidebarActionButton>
                </div>
            )}
        </div>
    );
};
