import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useApi } from '../../../hooks/useApi';
import '../../../styles/globals.css';
import PersonaEditor from './PersonaEditor';
import NewPersonaModal from './NewPersonaModal';
import { ExtendedUserResponse } from '../../../api/users';
import { ExtendedPersona } from '../../../api/personas';
import { BulkAssignModal } from './PersonaBulkAssignModal';
import { PersonaTrackedDocsModal } from './PersonaTrackedDocsModal';
import { PersonaGroupsManagementModal } from './PersonaGroupsManagementModal';
import { IconTrash } from '@tabler/icons-react';

const PersonaManagement: React.FC = (): JSX.Element => {
  const [editModalShow, setEditModalShow] = useState(false);
  const [assignModalShow, setAssignModalShow] = useState(false);
  const [newPersonaModalShow, setNewPersonaModalShow] = useState(false);
  const [trackedDocsModalShow, setTrackedDocsModalShow] = useState(false);
  const [groupsManagementModalShow, setGroupsManagementModalShow] = useState(false);
  const [allPersonas, setAllPersonas] = useState<ExtendedPersona[]>([]);
  const [allUsers, setAllUsers] = useState<ExtendedUserResponse[]>([]);
  const [chosenPersona, setChosenPersona] = useState<ExtendedPersona>({
    "id": "",
    "name": "",
    "has_index": false,
    "index_name": "",
    "index_top_k": 0,
    "is_default": false,
    "last_updated_at": "",
    "default_model": "",
    "system_prompt": "",
    "sharepoint_targets": [],
    "is_deleted": false,
    "max_tokens": 1024,
    "temperature": 0.7
  });
  const { getApiAllUsers, getApiAllPersonas, deleteApiPersona } = useApi();
  const navigate = useNavigate();

  async function fetchAllPersonas() {
    try {
      const personas = await getApiAllPersonas();
      if (Array.isArray(personas)) {
        setAllPersonas(personas);
      } else {
        console.error('The persona response is not an array:', personas);
      }
    } catch (error) {
      console.error('Failed to fetch all personas:', error);
    }
  }

  async function fetchAllUsers() {
    try {
      const users = await getApiAllUsers();
      if (Array.isArray(users)) {
        setAllUsers(users);
      } else {
        console.error('The users response is not an array:', users);
      }
    } catch (error) {
      console.error('Failed to fetch all users:', error);
    }
  }

  // Function to refresh both personas and users data
  async function refreshData() {
    await fetchAllPersonas();
    await fetchAllUsers();
  }

  // Initial data fetch for personas
  useEffect(() => {
    fetchAllPersonas();
  }, []);

  // Initial data fetch for users
  useEffect(() => {
    fetchAllUsers();
  }, []);

  const handleAssignButtonClick = (index: number) => {
    setChosenPersona(allPersonas[index]);
    setAssignModalShow(true);
    setEditModalShow(false);
    setNewPersonaModalShow(false);
  };

  const handleBackpageClick = (page: string) => {
    navigate(`/admin/${page}`); // Replace '/path-to-redirect' with your desired path
  };

  const handleEditButtonClick = (index: number) => {
    setChosenPersona(allPersonas[index]);
    setEditModalShow(true);
    setAssignModalShow(false);
    setNewPersonaModalShow(false);
  };

  const handleNewPersonaButtonClick = () => {
    setNewPersonaModalShow(true);
    setEditModalShow(false);
    setAssignModalShow(false);
  }

  const handleShowTrackedDocs = (index: number) => {
    setChosenPersona(allPersonas[index]);
    setTrackedDocsModalShow(true);
  }

  const handleShowGroupManagement = (index: number) => {
    setChosenPersona(allPersonas[index]);
    setGroupsManagementModalShow(true);
  }

  async function handleDeleteButtonClick(index: number) {
    if (confirm(`Are you sure you want to delete the '${allPersonas[index].name}' persona?`) === false) {
      return;
    }

    try {
      const response = await deleteApiPersona(allPersonas[index].id);
      console.log(response);
      refreshData();
    } catch (error) {
      console.error('Failed to call delete persona API:', error);
    }
  }

  return (
    <main className="flex flex-col flex-grow h-[calc(100vh-64px)] overflow-y-auto w-screen text-sm text-white dark:text-white bg-customGrey px-4pb-12">

      <div className="flex items-center justify-between p-4 h-16 mt-2" >
        <div className="flex items-center space-x-4">

          <button className="w-24 text-2xl bg-blueButton text-white rounded py-1 px-2 focus:outline-none focus:ring-2 focus:ring-blue-300" onClick={() => handleBackpageClick("adminHome")}>&#8592;</button>
          <button className="bg-green-500 text-white font-bold py-2 px-4 rounded hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-300" onClick={() => handleNewPersonaButtonClick()}>Add New Persona</button>

        </div>

      </div>



      <div className="w-full lg:px-24 pt-3 mx-auto pb-20">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 gap-8 overscroll-contain">
          {/* Map over your personas array to generate cards */}
          {allPersonas.map((persona, index) => (
            !persona.is_deleted ? (
              <div key={index} className="w-full bg-cardGrey px-4 py-8 flex flex-col justify-center items-center text-center rounded-lg">
                <div className="rounded pb-4 mb-2 text-white text-lg">
                  <strong>{persona.name}</strong>
                </div>
                <div className="grid grid-cols-2 gap-2">
                  <div className="mb-2">
                    <button className="py-2 w-full truncate bg-blueButton text-white rounded px-2 focus:outline-none focus:ring-2 focus:ring-blue-300" onClick={() => handleEditButtonClick(index)}>
                      Edit
                    </button>
                  </div>
                  <div className="mb-2">
                    <button className="py-2 w-full truncate bg-blueButton text-white rounded px-2 focus:outline-none focus:ring-2 focus:ring-blue-300" onClick={() => handleAssignButtonClick(index)}>Assign</button>
                  </div>
                  <div className="mb-2">
                    <button className="py-2 w-full truncate bg-blueButton text-white rounded px-2 focus:outline-none focus:ring-2 focus:ring-blue-300" onClick={() => handleShowTrackedDocs(index)}>Documents</button>
                  </div>
                  <div className="mb-2">
                    <button className="py-2 w-full truncate bg-blueButton text-white rounded px-2 focus:outline-none focus:ring-2 focus:ring-blue-300" onClick={() => handleShowGroupManagement(index)}>Groups</button>
                  </div>
                </div>
                <div className='mt-2'>
                  <button
                    className="py-2 bg-redButton text-white rounded px-2 focus:outline-none focus:ring-2 focus:ring-blue-300" // Added mt-2 for top margin
                    onClick={() => handleDeleteButtonClick(index)}
                  >
                    <IconTrash size={18} />
                  </button>
                </div>
              </div>
            ) : null
          ))}
        </div>
      </div>

      <NewPersonaModal
        show={newPersonaModalShow}
        onHide={() => setNewPersonaModalShow(false)}
        refreshData={refreshData}
      />

      <BulkAssignModal
        show={assignModalShow}
        onHide={() => setAssignModalShow(false)}
        persona={chosenPersona}
        allUsers={allUsers}
      />

      <PersonaEditor
        show={editModalShow}
        onHide={() => setEditModalShow(false)}
        persona={chosenPersona}
        refreshData={refreshData}
      />

      <PersonaTrackedDocsModal
        show={trackedDocsModalShow}
        onHide={() => setTrackedDocsModalShow(false)}
        persona={chosenPersona}
      />

      <PersonaGroupsManagementModal
        isOpen={groupsManagementModalShow}
        onHide={() => setGroupsManagementModalShow(false)}
        personaId={chosenPersona.id}
      />

    </main>
  );
};

export default PersonaManagement;

