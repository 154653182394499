import { useCallback, useContext, useEffect, useState } from "react";

import { useCreateReducer } from "../../hooks/useCreateReducer";
import { saveFolders } from "../../utils/app/folders";

import { Conversation } from "../../types/chat";

import HomeContext from "../../pages/chatv2/Chatv2.context";

import { ChatFolders } from "./components/ChatFolders";
import { ChatbarSettings } from "./components/ChatbarSettings";
import { Chats } from "./components/Chats";

import Sidebar from "../Sidebar";
import ChatbarContext from "./Chatbar.context";
import { ChatbarInitialState, initialState } from "./Chatbar.state";

import { v4 as uuidv4 } from "uuid";
import { ChatSummary } from "../../api/chat";
import { useApi } from "../../hooks/useApi";
import { useDebounce } from "../../hooks/useDebounce";

export const Chatbar = () => {
    const chatBarContextValue = useCreateReducer<ChatbarInitialState>({
        initialState
    });

    const {
        state: { chats, showChatbar, folders },
        dispatch: homeDispatch,
        handleCreateFolder,
        handleNewChat,
        handleUpdateChat: handleUpdateConversation
    } = useContext(HomeContext);

    const {
        state: { searchTerm, filteredChats },
        dispatch: chatDispatch
    } = chatBarContextValue;

    const { deleteApiUserChat, newApiUserChat, searchApiUserChatMessages, deleteApiAllUserChats } = useApi();

    const debouncedSearchTerm = useDebounce(searchTerm, 500);

    const handleExportData = () => {};

    // const handleImportConversations = (data: SupportedExportFormats) => {
    //   const { history, chats, folders, prompts }: LatestExportFormat = importData(data);
    //   homeDispatch({ field: 'chats', value: history });
    //   homeDispatch({
    //     field: 'selectedChat',
    //     value: chats[chats.length - 1],
    //   });
    //   homeDispatch({ field: 'folders', value: folders });
    //   homeDispatch({ field: 'userPrompts', value: prompts });

    //   window.location.reload();
    // };

    const handleClearConversations = async () => {
        await deleteApiAllUserChats();
        const newChat = await newApiUserChat({ title: "New Chat" });

        homeDispatch({ field: "selectedChatSummary", value: newChat });
        homeDispatch({ field: "chats", value: [newChat] });

        const updatedFolders = folders.filter(f => f.type !== "chat");

        homeDispatch({ field: "folders", value: updatedFolders });
        saveFolders(updatedFolders);
    };

    const handleDeleteChat = async (chat: ChatSummary) => {
        const updatedChats = chats.filter(c => c.id !== chat.id);

        homeDispatch({ field: "chats", value: updatedChats });
        chatDispatch({ field: "searchTerm", value: "" });

        await deleteApiUserChat(chat.id);

        if (updatedChats.length > 0) {
            // set selected chat to most recent chat
            const mostRecent = updatedChats.reduce((mostRecent, currentChat) => {
                if (new Date(mostRecent.last_updated_at) < new Date(currentChat.last_updated_at)) {
                    return currentChat;
                } else {
                    return mostRecent;
                }
            }, chats[0]);

            homeDispatch({ field: "selectedChatSummary", value: mostRecent });
        } else {
            const chat = await newApiUserChat({ title: "New Chat" });

            homeDispatch({
                field: "selectedChatSummary",
                value: chat
            });
        }
    };

    const handleToggleChatbar = () => {
        homeDispatch({ field: "showChatbar", value: !showChatbar });
        localStorage.setItem("showChatbar", JSON.stringify(!showChatbar));
    };

    const handleDrop = (e: any) => {
        if (e.dataTransfer) {
            const conversation = JSON.parse(e.dataTransfer.getData("conversation"));
            handleUpdateConversation(conversation, { key: "folderId", value: 0 });
            chatDispatch({ field: "searchTerm", value: "" });
            e.target.style.background = "none";
        }
    };

    useEffect(() => {
        if (debouncedSearchTerm) {
            let filteredChats: ChatSummary[] = [];

            const searchChats = async () => {
                const filteredChatIds = await searchApiUserChatMessages(searchTerm);
                const chatsIdsMatchingTitle = chats.filter(chat => chat.title.toLowerCase().includes(searchTerm.toLowerCase())).map(c => c.id);
                const allChatIds = new Set([...filteredChatIds, ...chatsIdsMatchingTitle]);
                filteredChats = chats.filter(chat => allChatIds.has(chat.id));

                chatDispatch({
                    field: "filteredChats",
                    value: filteredChats
                });
            };

            searchChats();
        } else {
            chatDispatch({
                field: "filteredChats",
                value: chats
            });
        }
    }, [debouncedSearchTerm, chats]);

    return (
        <ChatbarContext.Provider
            value={{
                ...chatBarContextValue,
                handleDeleteChat,
                handleClearConversations
            }}
        >
            <Sidebar<ChatSummary>
                side={"left"}
                isOpen={showChatbar}
                addItemButtonTitle={"New chat"}
                addSharedItemButtonTitle={""}
                showAddSharedItemButton={false}
                itemComponent={<Chats chats={filteredChats} />}
                folderComponent={<ChatFolders searchTerm={searchTerm} />}
                items={filteredChats}
                searchTerm={searchTerm}
                handleSearchTerm={(searchTerm: string) => chatDispatch({ field: "searchTerm", value: searchTerm })}
                toggleOpen={handleToggleChatbar}
                handleCreateItem={handleNewChat}
                handleCreateFolder={() => handleCreateFolder("New folder", "chat")}
                handleDrop={handleDrop}
                footerComponent={<ChatbarSettings />}
            />
        </ChatbarContext.Provider>
    );
};
