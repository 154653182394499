import { PromptResponse } from '../../api/prompts';
import { Prompt } from '../../types/prompt';

export interface PromptbarInitialState {
  searchTerm: string;
  filteredUserPrompts: PromptResponse[];
  filteredSharedPrompts: PromptResponse[];
}

export const initialState: PromptbarInitialState = {
  searchTerm: '',
  filteredUserPrompts: [],
  filteredSharedPrompts: [],
};
