import { ChatSummary } from '../../api/chat';
import { Persona } from '../../api/personas';
import { PromptResponse } from '../../api/prompts';
import { UserResponse } from '../../api/users';
import { Message } from '../../types/chat';
import { ErrorMessage } from '../../types/error';
import { FolderInterface } from '../../types/folder';
import { Prompt } from '../../types/prompt';

export interface ChatInitialState {
    apiKey: string;
    loading: boolean;
    lightMode: 'light' | 'dark';
    messageIsStreaming: boolean;
    modelError: ErrorMessage | null;
    folders: FolderInterface[];
    chats: ChatSummary[];
    selectedChatSummary: ChatSummary | undefined;
    chatHasFiles: boolean;
    currentMessage: Message | undefined;
    userPrompts: PromptResponse[];
    sharedPrompts: PromptResponse[];
    temperature: number;
    showChatbar: boolean;
    showPromptbar: boolean;
    currentFolder: FolderInterface | undefined;
    messageError: boolean;
    searchTerm: string;
    serverSideApiKeyIsSet: boolean;
    serverSidePluginKeysSet: boolean;
    loggedOnUser: UserResponse | undefined;
    loadingAllChats: boolean;
    loadingUserDetails: boolean;
    loadingPrompts: boolean;
    selectedPersonaId: string;
}

export const initialState: ChatInitialState = {
    apiKey: '',
    loading: false,
    lightMode: 'light',
    messageIsStreaming: false,
    modelError: null,
    folders: [],
    chats: [],
    selectedChatSummary: undefined,
    chatHasFiles: false,
    currentMessage: undefined,
    userPrompts: [],
    sharedPrompts: [],
    temperature: 1,
    showPromptbar: true,
    showChatbar: true,
    currentFolder: undefined,
    messageError: false,
    searchTerm: '',
    serverSideApiKeyIsSet: false,
    serverSidePluginKeysSet: false,
    loggedOnUser: undefined,
    loadingAllChats: true,
    loadingPrompts: true,
    loadingUserDetails: true,
    selectedPersonaId: "",
};
