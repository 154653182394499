import { FC, useEffect } from 'react';

import { Prompt } from '../../../types/prompt';

import { PromptComponent } from './Prompt';
import { PromptResponse } from '../../../api/prompts';

interface Props {
  userPrompts: PromptResponse[];
  sharedPrompts: PromptResponse[];
}

export const UserPrompts: FC<Props> = ({ userPrompts, sharedPrompts }) => {
  return (
    <div>
      {userPrompts.length > 0 &&
        <div className="flex w-full flex-col gap-1 border-b border-white/20 pb-2">
          <p className='text-center text-bold pt-1 pb-1'>My prompts</p>
          {userPrompts
            .slice()
            .reverse()
            .map((prompt, index) => (
              <PromptComponent key={index} prompt={prompt} isShared={false} />
            ))}
        </div>
      }

      {sharedPrompts.length > 0 && (
        <div className='flex w-full flex-col gap-1'>
          <p className='text-center text-bold pt-3 pb-1'>Shared prompts</p>
          {sharedPrompts
            .slice()
            .reverse()
            .map((prompt, index) => (
              <PromptComponent key={index} prompt={prompt} isShared={true} />
            ))}
        </div>
      )}
    </div>
  );
};
