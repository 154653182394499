import { EntraGroup } from "./groups";

type CallApiFunction = (method: string, path: string, data: object | null, abortController: AbortController) => Promise<Response | null>;

export interface Persona {
    id: string;
    name: string;
}

export interface SharepointFolder {
    document_library_name: string,
    folder_path: string
}

export interface SharepointSite {
    site_path: string,
    included_folders: Array<SharepointFolder>
    excluded_folders: Array<SharepointFolder>
    include_site_pages: Boolean
}

export interface SharepointTargets {
    sharepoint_hostname: string,
    sites: Array<SharepointSite>
}

export interface ExtendedPersona {
    id: string;
    name: string;
    has_index: boolean;
    index_name: string;
    index_top_k: number;
    is_default: boolean;
    last_updated_at: string;
    default_model: string;
    system_prompt: string;
    sharepoint_targets: Array<SharepointTargets>;
    is_deleted: boolean;
    max_tokens: number;
    temperature: number;
}

export interface NewPersona {
    "name": string,
    "has_index": boolean,
    "index_name": string,
    "index_top_k": number,
    "is_default": boolean,
    "default_model": string,
    "system_prompt": string,
    "sharepoint_targets": Array<SharepointTargets>;
    "max_tokens": number;
    "temperature": number;
}

export interface TrackedDocument {
    id: string,
    file_name: string,
    last_modified_date_time: string,
    path: string
}

export const addPersonaToUser = async (callApi: CallApiFunction, userId: string, personaId: string): Promise<Response> => {// change response object
    const path = `/api/v1/personas/${personaId}/users/${userId}`;
    const response = await callApi("PUT", path, null, new AbortController());

    if (!response || !response.ok) {
        throw new Error("Failed to add persona to user");
    }

    return response;
}

export const getAllPersonas = async (callApi: CallApiFunction): Promise<ExtendedPersona[]> => {// change response object
    const path = `/api/v1/personas`;
    const response = await callApi("GET", path, null, new AbortController());

    if (!response || !response.ok) {
        throw new Error("Failed to get a list of personas");
    }

    const personas = await response.json() as ExtendedPersona[];
    return personas;
}

export const deleteUserFromPersona = async (callApi: CallApiFunction, userId: string, personaId: string): Promise<Response> => {
    const path = `/api/v1/personas/${personaId}/users/${userId}`;
    const response = await callApi("DELETE", path, null, new AbortController());

    if (!response || !response.ok) {
        throw new Error("Failed to remove persona from user");
    }

    return response;
}

export const updatePersonaDetails = async (callApi: CallApiFunction, persona: ExtendedPersona): Promise<Response> => {
    const path = `/api/v1/personas/${persona.id}`;
    const response = await callApi("PUT", path, persona, new AbortController());

    if (!response || !response.ok) {
        throw new Error("Failed to update persona details");
    }

    return response;
}

export const postNewPersona = async (callApi: CallApiFunction, persona: NewPersona): Promise<Response> => {
    const path = `/api/v1/personas`;
    const response = await callApi("POST", path, persona, new AbortController());

    if (!response || !response.ok) {
        throw new Error("Failed to upload new persona");
    }

    return response;
}

export const deletePersona = async (callApi: CallApiFunction, personaId: string): Promise<Response> => {
    const path = `/api/v1/personas/${personaId}`;
    const response = await callApi("DELETE", path, null, new AbortController());

    if (!response || !response.ok) {
        throw new Error("Failed to delete persona");
    }

    return response;
}

export const getPersonaTrackedDocuments = async (callApi: CallApiFunction, personaId: string): Promise<TrackedDocument[]> => {
    const path = `/api/v1/personas/${personaId}/trackeddocs`;
    const response = await callApi("GET", path, null, new AbortController());

    if (!response || !response.ok) {
        throw new Error("Failed to get tracked documents for persona");
    }

    const trackedDocuments = await response.json() as TrackedDocument[];
    return trackedDocuments;
}

export const getPersonaAssignedGroups = async (callApi: CallApiFunction, personaId: string): Promise<EntraGroup[]> => {
    const path = `/api/v1/personas/${personaId}/groups`;
    const response = await callApi("GET", path, null, new AbortController());

    if (!response || !response.ok) {
        throw new Error("Failed to get groups assigned to persona");
    }

    const groups = await response.json() as EntraGroup[];
    return groups;
}

export const setPersonaAssignedGroups = async (callApi: CallApiFunction, personaId: string, groups: EntraGroup[]): Promise<Response> => {
    const path = `/api/v1/personas/${personaId}/groups`;
    const response = await callApi("POST", path, groups, new AbortController());

    if (!response || !response.ok) {
        throw new Error("Failed to set groups assigned to persona");
    }

    return response;
}

