import { useState, useEffect } from 'react';
import { useApi } from '../../../hooks/useApi';
import Swal from 'sweetalert2';
import '../../../styles/globals.css';
import { ExtendedUserResponse } from '../../../api/users';
import { Spinner } from '@fluentui/react';
import { ExtendedPersona, TrackedDocument } from '../../../api/personas';

export interface PersonaTrackedDocsModalProps {
    show: boolean;
    onHide: () => void;
    persona: ExtendedPersona;
}

export function PersonaTrackedDocsModal(props: PersonaTrackedDocsModalProps) {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [trackedDocuments, setTrackedDocuments] = useState<TrackedDocument[]>([]);
    const [searchTerm, setSearchTerm] = useState("");
    const { getApiPersonaTrackedDocs } = useApi();

    useEffect(() => {
        if (props.show) {
            setIsLoading(true);

            const getTrackedDocs = async () => {
                try {
                    const docs = await getApiPersonaTrackedDocs(props.persona.id);
                    setTrackedDocuments(docs);
                } catch (e) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error fetching tracked documents',
                        text: (e as Error).message,
                    });
                } finally {
                    setIsLoading(false);
                }
            }

            getTrackedDocs();
        }
    }, [props.show, props.persona.id]);

    const handleSearchChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
        setSearchTerm(event.target.value);
    };

    const handleHide = () => {
        setSearchTerm("");
        setTrackedDocuments([]);
        props.onHide();
    }

    return (
        <div
            className={`fixed inset-0 z-50 overflow-auto bg-smokeLight flex ${props.show ? '' : 'hidden'}`}
        >
            <div className="relative h-4/5 p-6 bg-white w-full max-w-7xl m-auto flex-col flex rounded-lg justify-between">

                <div className="flex items-center justify-center p-4 border-b border-solid border-gray-300 rounded-t">
                    <h3 className="text-xl font-semibold" id="contained-modal-title-vcenter" style={{ color: 'black' }}>
                        {props.persona.name} Tracked Documents
                    </h3>
                </div>


                <div className="p-2">

                    <div className="h-full">
                        <div className="flex my-2 w-full">
                            <input
                                type="text"
                                placeholder="Search..."
                                onChange={handleSearchChange}
                                value={searchTerm}
                                className="form-input h-8 text-base block w-full px-4 py-1 mb-2 text-gray-700 bg-white border border-solid border-gray-300 rounded-lg transition ease-in-out focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                            />
                        </div>
                    </div>
                </div>

                <div className="overflow-auto flex-grow">

                    <table className="w-full">

                        <thead>
                            <tr style={{ color: 'black' }}>
                                <th className="border px-4 py-2">File Path</th>
                                <th className="border px-4 py-2">Last Modified</th>
                            </tr>
                        </thead>

                        <tbody>
                            {trackedDocuments.filter(d => d.path.toLowerCase().includes(searchTerm)).map((doc) => (
                                <tr key={doc.id} style={{ color: 'black' }}>
                                    <td className="border px-4 py-2">{doc.path}</td>
                                    <td className="border px-4 py-2">{doc.last_modified_date_time}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                <div className="flex items-center space-x-4 justify-end">
                    <button className="w-20 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded" onClick={handleHide}>
                        Close
                    </button>
                </div>
            </div >
        </div >
    );
}


