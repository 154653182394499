import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useCreateReducer } from '../../hooks/useCreateReducer';

import { Prompt } from '../../types/prompt';

import HomeContext from '../../pages/chatv2/Chatv2.context';

import { PromptFolders } from './components/PromptFolders';
import { PromptbarSettings } from './components/PromptbarSettings';
import { UserPrompts } from './components/Prompts';

import Sidebar from '../Sidebar';
import PromptbarContext from './PromptBar.context';
import { PromptbarInitialState, initialState } from './Promptbar.state';

import { v4 as uuidv4 } from 'uuid';
import { PromptResponse } from '../../api/prompts';
import { useApi } from '../../hooks/useApi';

const Promptbar = () => {
  const { t } = useTranslation('promptbar');

  const promptBarContextValue = useCreateReducer<PromptbarInitialState>({
    initialState,
  });

  const {
    state: { userPrompts, sharedPrompts, showPromptbar, loggedOnUser },
    dispatch: homeDispatch,
    handleCreateFolder,
  } = useContext(HomeContext);

  const {
    updateApiUserPrompt,
    deleteApiUserPrompt,
    newApiUserPrompt,
    updateApiSharedPrompt,
    deleteApiSharedPrompt,
    newApiSharedPrompt,
  } = useApi();

  const {
    state: { searchTerm, filteredUserPrompts, filteredSharedPrompts },
    dispatch: promptDispatch,
  } = promptBarContextValue;

  const handleTogglePromptbar = () => {
    homeDispatch({ field: 'showPromptbar', value: !showPromptbar });
    localStorage.setItem('showPromptbar', JSON.stringify(!showPromptbar));
  };

  const handleCreatePrompt = async (isShared: boolean | undefined) => {
    if (isShared) {
      const newPrompt = await newApiSharedPrompt({ name: `Prompt ${sharedPrompts.length + 1}`, content: '', description: '' })
      const updatedPrompts = [...sharedPrompts, newPrompt];
      homeDispatch({ field: 'sharedPrompts', value: updatedPrompts });
    } else {
      const newPrompt = await newApiUserPrompt({ name: `Prompt ${userPrompts.length + 1}`, content: '', description: '' })
      const updatedPrompts = [...userPrompts, newPrompt];
      homeDispatch({ field: 'userPrompts', value: updatedPrompts });
    }
  };

  const handleDeletePrompt = async (prompt: PromptResponse, isShared: boolean) => {
    if (isShared) {
      await deleteApiSharedPrompt(prompt.id);
      const updatedSharedPrompts = sharedPrompts.filter((p) => p.id !== prompt.id);
      homeDispatch({ field: 'sharedPrompts', value: updatedSharedPrompts });
    } else {
      await deleteApiUserPrompt(prompt.id);
      const updatedPrompts = userPrompts.filter((p) => p.id !== prompt.id);
      homeDispatch({ field: 'userPrompts', value: updatedPrompts });
    }

  };

  const handleUpdatePrompt = async (prompt: PromptResponse, isShared: boolean) => {
    if (isShared) {
      await updateApiSharedPrompt(prompt.id, { ...prompt })
      const updatedSharedPrompts = sharedPrompts.map((p) => {
        if (p.id === prompt.id) {
          return prompt;
        }

        return p;
      });
      homeDispatch({ field: 'sharedPrompts', value: updatedSharedPrompts });
    } else {
      await updateApiUserPrompt(prompt.id, { ...prompt })

      const updatedPrompts = userPrompts.map((p) => {
        if (p.id === prompt.id) {
          return prompt;
        }

        return p;
      });
      homeDispatch({ field: 'userPrompts', value: updatedPrompts });
    };
  };

  const handleDrop = (e: any) => {
    if (e.dataTransfer) {
      const prompt = JSON.parse(e.dataTransfer.getData('prompt'));

      const updatedPrompt = {
        ...prompt,
        folderId: e.target.dataset.folderId,
      };

      handleUpdatePrompt(updatedPrompt, false);

      e.target.style.background = 'none';
    }
  };

  useEffect(() => {
    if (searchTerm) {
      promptDispatch({
        field: 'filteredUserPrompts',
        value: userPrompts.filter((prompt) => {
          const searchable =
            prompt.name.toLowerCase() +
            ' ' +
            prompt.description.toLowerCase() +
            ' ' +
            prompt.content.toLowerCase();
          return searchable.includes(searchTerm.toLowerCase());
        }),
      });
      promptDispatch({
        field: 'filteredSharedPrompts',
        value: sharedPrompts.filter((prompt) => {
          const searchable =
            prompt.name.toLowerCase() +
            ' ' +
            prompt.description.toLowerCase() +
            ' ' +
            prompt.content.toLowerCase();
          return searchable.includes(searchTerm.toLowerCase());
        }),
      });
    } else {
      promptDispatch({ field: 'filteredUserPrompts', value: userPrompts });
      promptDispatch({ field: 'filteredSharedPrompts', value: sharedPrompts });
    }
  }, [searchTerm, userPrompts, sharedPrompts]);

  return (
    <PromptbarContext.Provider
      value={{
        ...promptBarContextValue,
        handleCreatePrompt,
        handleDeletePrompt,
        handleUpdatePrompt,
      }}
    >
      <Sidebar<PromptResponse>
        side={'right'}
        isOpen={showPromptbar}
        addItemButtonTitle={t('New prompt')}
        addSharedItemButtonTitle={t('New shared prompt')}
        showAddSharedItemButton={loggedOnUser?.is_admin ?? false}
        itemComponent={
          <UserPrompts
            userPrompts={filteredUserPrompts.filter((prompt) => !prompt.folder_id)}
            sharedPrompts={filteredSharedPrompts.filter((prompt) => !prompt.folder_id)}
          />
        }
        folderComponent={<PromptFolders />}
        items={[ ...filteredUserPrompts, ...filteredSharedPrompts ]}
        searchTerm={searchTerm}
        handleSearchTerm={(searchTerm: string) =>
          promptDispatch({ field: 'searchTerm', value: searchTerm })
        }
        toggleOpen={handleTogglePromptbar}
        handleCreateItem={handleCreatePrompt}
        handleCreateFolder={() => handleCreateFolder(t('New folder'), 'prompt')}
        handleDrop={handleDrop}
      />
    </PromptbarContext.Provider>
  );
};

export default Promptbar;
