export interface UserResponse {
    email: string;
    is_admin: boolean;
    personas: Persona[];
}

export interface ExtendedUserResponse {
    user_id: string;
    email: string;
    is_admin: boolean;
    personas: Persona[];
}

export interface Persona {
    id: string;
    name: string;
}

export interface AadUserResponse {
    id: string
    email: string;
    displayName: string;
}

type CallApiFunction = (method: string, path: string, data: object | null, abortController: AbortController) => Promise<Response | null>;

export const getLoggedOnUser = async (callApi: CallApiFunction): Promise<UserResponse> => {
    const path = `/api/v1/users/me`;
    const response = await callApi("GET", path, null, new AbortController());

    if (!response || !response.ok) {
        throw new Error("Failed to get logged on user.");
    }

    const user = await response.json() as UserResponse;
    return user;
}

export const searchEntraUsers = async (callApi: CallApiFunction, searchTerm: string): Promise<AadUserResponse[]> => {
    const path = `/api/v1/users/entra/search?search=${searchTerm}`;
    const response = await callApi("GET", path, null, new AbortController());

    if (!response || !response.ok) {
        throw new Error("Failed to search for users");
    }

    const users = await response.json() as AadUserResponse[];
    return users;
}

export const getAllUsers = async (callApi: CallApiFunction): Promise<ExtendedUserResponse[]> => {
    const path = `/api/v1/users/`;
    const response = await callApi("GET", path, null, new AbortController());

    if (!response || !response.ok) {
        throw new Error("Failed to request list of users");
    }

    const users = await response.json() as ExtendedUserResponse[];
    return users;
}


export const getUserById = async (callApi: CallApiFunction, userId: string): Promise<ExtendedUserResponse> => {
    const path = `/api/v1/users/${userId}`;
    const response = await callApi("GET", path, null, new AbortController());

    if (!response || !response.ok) {
        throw new Error("Failed to request list of users");
    }

    const user = await response.json() as ExtendedUserResponse;
    return user;
}


export const addUser = async (callApi: CallApiFunction, email: string): Promise<ExtendedUserResponse> => {
    const path = `/api/v1/users/`;
    const response = await callApi("POST", path, { email }, new AbortController());

    if (!response || !response.ok) {
        throw new Error("Failed to add user");
    }

    const user = await response.json() as ExtendedUserResponse;
    return user;
}



