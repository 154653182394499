import { Persona } from "./users";

export interface ChatSummary {
    id: string;
    title: string;
    created_at: Date;
    last_updated_at: Date;
    folder_id: string | null;
}

export type ChatMessageRole = 'user' | 'assistant';

export interface ChatMessage {
    content: string;
    role: string;
    created_at: Date;
    persona: Persona | undefined;
}

export type ChatFileIndexType = 'summary' | 'qna'

export interface ChatFileIndex {
    id: string;
    type: ChatFileIndexType;
    created_at: Date;
    file_name: string
}

export interface ChatResponse {
    id: string;
    title: string;
    created_at: Date;
    messages: ChatMessage[];
    last_updated_at: Date;
    file_indexes: ChatFileIndex[];
}

export interface UpdateChatRequest {
    title: string;
    messages: ChatMessage[];
    persona_id: string | undefined;
}

export interface CreateChatRequest {
    title: string;
}

type CallApiFunction = (method: string, path: string, data: object | null, abortController: AbortController) => Promise<Response | null>;

export const getAllUserChats = async (callApi: CallApiFunction): Promise<ChatSummary[]> => {
    const path = '/api/v1/chat';
    const response = await callApi("GET", path, null, new AbortController());

    if (!response || !response.ok) {
        throw new Error("Failed to get user chats.");
    }

    const chats = await response.json() as ChatSummary[];
    return chats;
}

export const getUserChat = async (callApi: CallApiFunction, chatId: string): Promise<ChatResponse> => {
    const path = `/api/v1/chat/${chatId}`;
    const response = await callApi("GET", path, null, new AbortController());

    if (!response || !response.ok) {
        throw new Error("Failed to get user chats.");
    }

    const chat = await response.json() as ChatResponse;
    return chat;
}

export const newUserChat = async (callApi: CallApiFunction, createChatRequest: CreateChatRequest): Promise<ChatResponse> => {
    const path = `/api/v1/chat`;
    const response = await callApi("POST", path, createChatRequest, new AbortController());

    if (!response || !response.ok) {
        if (response && response.status === 403) {
            throw new Error("Maximum number of chats reached")
        }

        throw new Error("Failed to get user chats.");
    }

    const chat = await response.json() as ChatResponse;
    return chat;
}

export const updateUserChat = async (callApi: CallApiFunction, updateChatRequest: UpdateChatRequest, chatId: string): Promise<Response> => {
    const path = `/api/v1/chat/${chatId}`;
    const response = await callApi("PUT", path, updateChatRequest, new AbortController());

    if (!response || !response.ok) {
        throw new Error("Failed to update user chat.");
    }

    return response;
}

export const updateUserChatTitle = async (callApi: CallApiFunction, newTitle: string, chatId: string): Promise<Response> => {
    const path = `/api/v1/chat/${chatId}/title`;
    const response = await callApi("PUT", path, { title: newTitle }, new AbortController());

    if (!response || !response.ok) {
        throw new Error("Failed to update user chat title.");
    }

    return response;
}

export const updateUserChatAndGetStreamResponse = async (callApi: CallApiFunction, updateChatRequest: UpdateChatRequest, chatId: string): Promise<Response> => {
    const path = `/api/v1/chat/${chatId}/stream`;
    const response = await callApi("PUT", path, updateChatRequest, new AbortController());

    if (!response || !response.ok) {
        if (response && response.status === 403) {
            throw new Error("Maximum number of chat tokens reached")
        }

        throw new Error("Failed to update user chat.");
    }

    return response;
}

export const deleteUserChat = async (callApi: CallApiFunction, chatId: string): Promise<Response> => {
    const path = `/api/v1/chat/${chatId}`;
    const response = await callApi("DELETE", path, null, new AbortController());

    if (!response || !response.ok) {
        throw new Error("Failed to delete user chat.");
    }

    return response;
}

export const deleteAllUserChats = async (callApi: CallApiFunction): Promise<Response> => {
    const path = `/api/v1/chat`;
    const response = await callApi("DELETE", path, null, new AbortController());

    if (!response || !response.ok) {
        throw new Error("Failed to delete user chats.");
    }

    return response;
}

export const uploadChatFile = async (callApi: CallApiFunction, file: File, chatId: string, fileIndexType: ChatFileIndexType = 'summary'): Promise<Response> => {
    const path = `/api/v1/chat/${chatId}/files`;
    const formData = new FormData();
    formData.append('file', file);
    formData.append('file_index_type', fileIndexType);

    console.log("Uploading file:", file, formData)

    const response = await callApi("POST", path, formData, new AbortController());

    if (!response || !response.ok) {
        throw new Error("Failed to upload chat file.");
    }

    return response;
}

export const deleteChatFile = async (callApi: CallApiFunction, chatId: string, fileId: string): Promise<Response> => {
    const path = `/api/v1/chat/${chatId}/files/${fileId}`;
    const response = await callApi("DELETE", path, null, new AbortController());

    if (!response || !response.ok) {
        throw new Error("Failed to delete chat file.");
    }

    return response;
}