import { memo, useEffect, useRef, useState } from "react";
import { useCreateReducer } from "../../hooks/useCreateReducer";

import "../../styles/globals.css";

import { saveChat } from "../../utils/app/conversation";
import { saveFolders } from "../../utils/app/folders";
import { getSettings } from "../../utils/app/settings";
import { KeyValuePair } from "../../types/data";
import { FolderInterface, FolderType } from "../../types/folder";
import { Chat } from "../../components/Chat/Chat";
import { Chatbar } from "../../components/Chatbar/Chatbar";
import { Navbar } from "../../components/Mobile/Navbar";
import Promptbar from "../../components/Promptbar";
import HomeContext from "./Chatv2.context";
import { ChatInitialState, initialState } from "./Chat.state";
import { v4 as uuidv4 } from "uuid";
import { ThreeDots } from "react-loader-spinner";
import { useApi } from "../../hooks/useApi";
import { ChatSummary, UpdateChatRequest } from "../../api/chat";
import toast, { Toaster } from "react-hot-toast";

interface Props {
    slim: boolean;
    defaultPersona: string;
    assistantName: string;
}

const Chatv2 = ({ slim, defaultPersona, assistantName }: Props) => {
    const [initialRender, setInitialRender] = useState<boolean>(true);
    const {
        getApiAllUserChats,
        getApiUserChat,
        updateApiUserChat,
        updateApiUserChatTitle,
        newApiUserChat,
        getApiAllUserPrompts,
        getApiAllSharedPrompts,
        getApiLoggedOnUser
    } = useApi();

    const contextValue = useCreateReducer<ChatInitialState>({
        initialState
    });

    const {
        state: { lightMode, folders, selectedChatSummary: selectedChat, chats, loadingAllChats, loadingPrompts, loadingUserDetails },
        dispatch
    } = contextValue;

    const stopConversationRef = useRef<boolean>(false);

    // FETCH MODELS ----------------------------------------------

    const handleSelectChat = (chat: ChatSummary) => {
        dispatch({
            field: "selectedChatSummary",
            value: chat
        });

        saveChat(chat);
    };

    // FOLDER OPERATIONS  --------------------------------------------

    const handleCreateFolder = (name: string, type: FolderType) => {
        const newFolder: FolderInterface = {
            id: uuidv4(),
            name,
            type
        };

        const updatedFolders = [...folders, newFolder];

        dispatch({ field: "folders", value: updatedFolders });
        saveFolders(updatedFolders);
    };

    const handleDeleteFolder = (folderId: string) => {
        // TODO: implement folders
        // const updatedFolders = folders.filter((f) => f.id !== folderId);
        // dispatch({ field: 'folders', value: updatedFolders });
        // saveFolders(updatedFolders);
        // const updatedConversations: Conversation[] = conversations.map((c) => {
        //     if (c.folderId === folderId) {
        //         return {
        //             ...c,
        //             folderId: null,
        //         };
        //     }
        //     return c;
        // });
        // dispatch({ field: 'conversations', value: updatedConversations });
        // saveConversations(updatedConversations);
        // const updatedPrompts: Prompt[] = prompts.map((p) => {
        //     if (p.folderId === folderId) {
        //         return {
        //             ...p,
        //             folderId: null,
        //         };
        //     }
        //     return p;
        // });
        // dispatch({ field: 'prompts', value: updatedPrompts });
        // savePrompts(updatedPrompts);
    };

    const handleUpdateFolder = (folderId: string, name: string) => {
        const updatedFolders = folders.map(f => {
            if (f.id === folderId) {
                return {
                    ...f,
                    name
                };
            }

            return f;
        });

        dispatch({ field: "folders", value: updatedFolders });

        saveFolders(updatedFolders);
    };

    // CONVERSATION OPERATIONS  --------------------------------------------

    const handleNewChat = async () => {
        try {
            const newChat = await newApiUserChat({ title: "New Chat" });
            const { messages, ...newChatSummary } = newChat;
            const updatedChats = [...chats, newChatSummary];

            dispatch({ field: "selectedChatSummary", value: newChatSummary });
            dispatch({ field: "chats", value: updatedChats });
        } catch (error) {
            const e = error as Error;
            if (e.message.includes("Maximum number of chats reached")) {
                toast.error("Maximum number of chats reached. Please contact your administrator to extend your quota.", { duration: 10000 });
            }
        }

        dispatch({ field: "loading", value: false });
    };

    const handleUpdateChat = async (chatId: string, data: KeyValuePair) => {
        const updatedSummary: ChatSummary = chats.find(c => c.id === chatId)!;

        if (data.key === "messages") {
            const chat = await getApiUserChat(chatId);

            const updatedChat = {
                ...chat,
                [data.key]: data.value
            };

            const updateChatRequest: UpdateChatRequest = {
                messages: updatedChat.messages,
                title: updatedChat.title,
                persona_id: undefined
            };

            await updateApiUserChat(chatId, updateChatRequest);
        } else if (data.key === "title") {
            await updateApiUserChatTitle(chatId, data.value as string);
            updatedSummary.title = data.value as string;
        }

        const updatedChats = chats.map(c => {
            if (c.id === chatId) {
                return {
                    ...c,
                    title: updatedSummary.title,
                    last_updated_at: new Date()
                };
            }

            return c;
        });

        dispatch({ field: "selectedChatSummary", value: updatedSummary });
        dispatch({ field: "chats", value: updatedChats });
    };

    // EFFECTS  --------------------------------------------

    useEffect(() => {
        if (window.innerWidth < 640) {
            dispatch({ field: "showChatbar", value: false });
        }
    }, [selectedChat]);

    // ON LOAD --------------------------------------------

    useEffect(() => {
		let ignore = false;
        const settings = getSettings();
        if (settings.theme) {
            dispatch({
                field: "lightMode",
                value: settings.theme
            });
        }

        // pca.loginPopup();
        if (window.innerWidth < 640) {
            dispatch({ field: "showChatbar", value: false });
            dispatch({ field: "showPromptbar", value: false });
        }

        const showChatbar = localStorage.getItem("showChatbar");
        if (showChatbar) {
            dispatch({ field: "showChatbar", value: showChatbar === "true" });
        }

        const showPromptbar = localStorage.getItem("showPromptbar");
        if (showPromptbar) {
            dispatch({ field: "showPromptbar", value: showPromptbar === "true" });
        }

        const fetchPrompts = async () => {
            const userPrompts = await getApiAllUserPrompts();
            const sharedPrompts = await getApiAllSharedPrompts();
            dispatch({ field: "userPrompts", value: userPrompts });
            dispatch({ field: "sharedPrompts", value: sharedPrompts });
            dispatch({ field: "loadingPrompts", value: false });
        };

        const fetchLoggedOnUser = async () => {
            const loggedOnUser = await getApiLoggedOnUser();
            dispatch({ field: "loggedOnUser", value: loggedOnUser });
            dispatch({ field: "loadingUserDetails", value: false });
        };

        const fetchChats = async () => {			
            let chats = await getApiAllUserChats();
			let chatName = "iFrame"
			if(defaultPersona != undefined){
				chatName = chatName + " " + defaultPersona
			}

            if (chats.length === 0) {
                const newChat = await newApiUserChat({ title: "New Chat" });
                chats = [{ ...newChat, folder_id: null }];
            }
            if (slim === true) {				
                let hasChat = chats.find(x => x.title === chatName)
                if (hasChat === undefined) {
					if (!ignore) {
						const newChat = await newApiUserChat({ title: chatName });
						chats = [{ ...newChat, folder_id: null }];
					}
                }
            }
			if (!ignore) {
            	dispatch({ field: "chats", value: chats });
			}

            // get the most recently updated chat and set it as selected
            const mostRecent = chats.reduce((mostRecent, currentChat) => {
                if (slim === true) {
                    let hasChat = chats.find(x => x.title === chatName)
                    if (hasChat != undefined) {
                        return hasChat;
                    }
                }
                if (new Date(mostRecent.last_updated_at) < new Date(currentChat.last_updated_at)) {
                    return currentChat;
                } else {
                    return mostRecent;
                }
            }, chats[0]);

            dispatch({ field: "selectedChatSummary", value: mostRecent });
            dispatch({ field: "loadingAllChats", value: false });
        };

        fetchChats();
        fetchPrompts();
        fetchLoggedOnUser();

		return () => {
			ignore = true;
		};
    }, [dispatch]);

    return (
        <HomeContext.Provider
            value={{
                ...contextValue,
                handleNewChat,
                handleCreateFolder,
                handleDeleteFolder,
                handleUpdateFolder,
                handleSelectChat: handleSelectChat,
                handleUpdateChat: handleUpdateChat
            }}
        >
            <Toaster />

            {((loadingAllChats || loadingPrompts || loadingUserDetails) && (
                <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col justify-center items-center">
                    <div className="text-xl font-semibold text-gray-800 dark:text-gray-400">Loading Shyrka</div>
                    <ThreeDots color="#202123" height={110} width={110} wrapperClass="flex justify-center items-center" />
                </div>
            )) ||
                (selectedChat && (
                    <main className={`flex flex-grow h-[calc(100vh-64px)] overflow-y-auto w-screen flex-col text-sm text-white dark:text-white ${lightMode}`}>
                        <div className="fixed top-0 w-full invisible">
                            <Navbar selectedChat={selectedChat} onNewConversation={handleNewChat} />
                        </div>

                        <div className="flex h-full w-full sm:pt-0">
                            {slim === true ? "" : <Chatbar />}
                            <div className="flex flex-1">
                                <Chat stopConversationRef={stopConversationRef} slim={slim} defaultPersona={defaultPersona} assistantName={assistantName} />
                            </div>
                            {slim === true ? "" : <Promptbar />}
                        </div>
                    </main>
                ))}
        </HomeContext.Provider>
    );
};
export default Chatv2;
