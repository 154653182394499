import { useState, useEffect } from 'react';
import { useApi } from '../../../hooks/useApi';
import Swal from 'sweetalert2';
import '../../../styles/globals.css';
import { ExtendedUserResponse } from '../../../api/users';
import { Spinner } from '@fluentui/react';


export function BulkAssignModal(props: any) {
    const [initialCheckedState, setInitialCheckedState] = useState<{ [email: string]: boolean }>({}); //list of user emails + boolean on selected
    const [checkedState, setCheckedState] = useState<{ [email: string]: boolean }>({}); //list of user emails + boolean on selected
    const selectedPersonaName = props.persona.name;
    const selectedPersonaId = props.persona.id;
    const allUsers = props.allUsers;
    const isDefaultPersona = props.persona.is_default;
    const { postAPIAddPersonaToUser, deleteApiUserFromPersona } = useApi();
    const [searchTerm, setSearchTerm] = useState("");
    const [isSaving, setIsSaving] = useState(false);

    useEffect(() => {
        setCheckedState({})
        setInitialCheckedState({})

        // Set the initial checked state based on the users' current personas
        allUsers.forEach((u: ExtendedUserResponse) => {
            if (u.personas.some(persona => persona.id === selectedPersonaId)) {
                setCheckedState(prevState => ({ ...prevState, [u.email]: true }));
                setInitialCheckedState(prevState => ({ ...prevState, [u.email]: true }));
            }
        })
    }, [allUsers, selectedPersonaId]);

    const checkAllBoxes = () => {
        const newState = Object.keys(checkedState).reduce((state: { [email: string]: boolean }, email: string) => {
            state[email] = true;
            return state;
        }, {});

        setCheckedState(newState);
    };

    // Function to update the checked state of an individual checkbox
    const handleCheckboxChange = (email: string) => {
        setCheckedState(prevState => ({
            ...prevState,
            [email]: !prevState[email],
        }));
    };

    const handleSearchChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
        setSearchTerm(event.target.value);
    };

    const handleHide = () => {
        setSearchTerm("");
        props.onHide();
    }

    async function handleBulkAssign() {
        setIsSaving(true);

        const failed: string[] = [];

        const assignOrUnassign = async (email: string, status: boolean) => {
            if (status === true) {
                try {
                    const userId = (allUsers.find((u: ExtendedUserResponse) => u.email === email)).user_id;
                    await postAPIAddPersonaToUser(userId, selectedPersonaId);
                } catch (error) {
                    console.error('Failed to post to API:', error);
                    failed.push(email);
                }
            } else if (status === false) {
                try {
                    const userId = (allUsers.find((u: ExtendedUserResponse) => u.email === email)).user_id;
                    await deleteApiUserFromPersona(userId, selectedPersonaId);
                } catch (error) {
                    failed.push(email);
                    console.error('Failed to post to API:', error);
                }
            }
        }

        const promises: Promise<void>[] = [];

        for (const email of Object.keys(checkedState)) {
            if (checkedState[email] !== initialCheckedState[email]) {
                const status = checkedState[email]; -
                    promises.push(assignOrUnassign(email, status));
            }
        }

        await Promise.all(promises);

        if (failed.length > 0) {
            Swal.fire({
                title: 'An error occurred',
                text: `There was an issue with the assignment for the following users: ${failed.join(', ')}.  Please try again.`,
                icon: 'error',
                confirmButtonText: 'OK'
            });
        }

        setInitialCheckedState(checkedState);
        setIsSaving(false);
        handleHide();
    }

    return (
        <div
            className={`fixed inset-0 z-50 overflow-auto bg-smokeLight flex ${props.show ? '' : 'hidden'}`}
        >
            <div className="relative h-4/5 p-6 bg-white w-full max-w-lg m-auto flex-col flex rounded-lg justify-between">

                <div className="flex items-center justify-center p-4 border-b border-solid border-gray-300 rounded-t">
                    <h3 className="text-xl font-semibold" id="contained-modal-title-vcenter" style={{ color: 'black' }}>
                        {selectedPersonaName}
                    </h3>
                </div>


                <div className="p-2">

                    {isDefaultPersona ? (
                        <div className='text-center text-base mt-3 mb-4 text-black' >
                            This is a default persona, assignments cannot be changed
                        </div>
                    ) : (
                        <div />
                    )}

                    <div className="h-full">
                        <div className="flex my-2 w-full">
                            <input
                                type="text"
                                placeholder="Search for a user..."
                                onChange={handleSearchChange}
                                value={searchTerm}
                                className="form-input h-8 text-base block w-full px-4 py-1 mb-2 text-gray-700 bg-white border border-solid border-gray-300 rounded-lg transition ease-in-out focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                            />
                        </div>
                    </div>
                </div>

                <div className="overflow-auto flex-grow">

                    <table className="w-full">

                        <thead>
                            <tr style={{ color: 'black' }}>
                                <th className="border px-4 py-2 flex items-center">
                                    <button className="bg-greenButton text-white rounded py-1 px-2 focus:outline-none focus:ring-2 focus:ring-red-300 mb-2" onClick={checkAllBoxes}>Select All</button>
                                </th>
                                <th className="border px-4 py-2">Email</th>
                            </tr>
                        </thead>

                        <tbody>
                            {allUsers.filter((user: ExtendedUserResponse) => user.email.toLowerCase().includes(searchTerm.toLowerCase())).map((user: ExtendedUserResponse, index: number) => (
                                <tr key={user.email} style={{ color: 'black' }}>
                                    <td className="border px-4 py-2 flex justify-center items-center">
                                        <input
                                            type="checkbox"
                                            value="1"
                                            id={`checkbox-${index}`}
                                            className="form-checkbox h-5 w-5 text-blue-600"
                                            // Ensure that we're falling back to false if the state isn't initialized yet
                                            checked={checkedState[user.email] || false}
                                            onChange={() => handleCheckboxChange(user.email)}
                                            disabled={isDefaultPersona}
                                        />
                                        <label htmlFor={`checkbox-${index}`} className="sr-only">Select</label>
                                    </td>
                                    <td className="border px-4 py-2">{user.email}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                <div className="flex items-center space-x-4 justify-end">
                    {!isDefaultPersona && (
                        <button className="w-20 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={handleBulkAssign}>
                            {isSaving ? <Spinner /> : 'Save'}
                        </button>
                    )}
                    <button className="w-20 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded" onClick={handleHide}>
                        Cancel
                    </button>
                </div>
            </div >
        </div >
    );
}


