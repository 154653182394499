import { useState, useEffect } from 'react';
import { useApi } from '../../../hooks/useApi';
import Swal from 'sweetalert2';
import { ExtendedPersona } from '../../../api/personas';
import SharepointTargetsModal from './SharepointTargetsModal';
import '../../../styles/globals.css';
import { IconInfoCircle } from '@tabler/icons-react';
import { Tooltip } from 'react-tooltip';



function PersonaEditor(props: any) {

    const [showIndexFields, setShowIndexFields] = useState(!!props.persona.has_index);
    const [currentPersona, setCurrentPersona] = useState<ExtendedPersona>({ ...props.persona });
    const [showSharepointTargetsModal, setShowSharepointTargetsModal] = useState(false);
    const { updateApiPersonaDetails } = useApi();

    // Parse the string into a Date object
    const date = new Date(props.persona.last_updated_at);
    // Options for the toLocaleString to show date and time in a human-readable format
    const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false // You can set this to true if you prefer 12-hour time format
    };
    // Convert the Date object to a local string with the specified options
    const formattedDate = date.toLocaleString('en-GB', options);

    // Synchronize the state with the prop when the component mounts or when the prop changes
    useEffect(() => {
        setShowIndexFields(props.persona.has_index);
        setCurrentPersona({ ...props.persona });
        console.log(props.persona);
    }, [props.persona]);

    const handleChange = (e: any) => {
        const { name, value } = e.target;

        // const invalidChars = /["]/g;
        // const sanitizedValue = value.replace(invalidChars, '\\$1');

        if (name === "has_index") {
            // Convert string value back to a boolean
            const boolValue = value === "true";
            setCurrentPersona(prevState => ({
                ...prevState,
                [name]: boolValue
            }));
            setShowIndexFields(boolValue);
        } else {
            setCurrentPersona(prevState => ({
                ...prevState,
                [name]: value
            }));
        }
    };

    const handleSharepointTargetsChange = (updatedTargets: any) => {
        setCurrentPersona(prevState => ({
            ...prevState,
            sharepoint_targets: updatedTargets
        }));
    };


    async function handleSubmit(e: any) {
        e.preventDefault()
        let ResponseError = false;
        let APIError = false;

        console.log(currentPersona.sharepoint_targets);

        try {
            const response = await updateApiPersonaDetails(currentPersona);
            if (!response.ok) {
                ResponseError = true;
            }
        } catch (error) {
            console.error('Failed to post to API:', error);
            APIError = true;
        }

        if (!ResponseError && !APIError) {
            await Swal.fire({
                title: 'Success!',
                text: 'The persona changes have been saved.',
                icon: 'success',
                confirmButtonText: 'OK'
            }).then(() => {
                props.refreshData();
                props.onHide();
            });

        } else if (ResponseError) {
            await Swal.fire({
                title: 'Error!',
                text: 'There was an issue with saving the persona changes.',
                icon: 'error',
                confirmButtonText: 'OK'
            }).then(() => {
                props.refreshData();
                props.onHide();
            });
        }
        else if (APIError) {
            await Swal.fire({
                title: 'Error!',
                text: 'There was an error communicating with the API.',
                icon: 'error',
                confirmButtonText: 'OK'
            }).then(() => {
                props.refreshData();
                props.onHide();
            });
        }
    }


    return (
        <div className={`fixed inset-0 z-50 overflow-auto bg-smokeLight flex items-center justify-center ${props.show ? '' : 'hidden'}`}>
            <div className="relative mx-auto p-5 border shadow-lg rounded-md bg-white w-full max-w-4xl h-4/5 max-h-full flex flex-col">
                <div className="flex-grow overflow-y-auto p-3">
                    <h3 className="text-lg leading-6 font-medium text-gray-900 text-center">
                        Editing Persona: {props.persona.name}
                    </h3>
                    <div style={{ color: 'black', textAlign: 'center', fontSize: '15px', marginTop: '5px' }}>Last Updated: {formattedDate}</div>
                    <form id="edit-persona-form" className="mt-5 grid grid-cols-4 gap-4" onSubmit={handleSubmit}>

                        {/* Name field */}
                        <div className="col-span-1">
                            <label htmlFor="name" className="block pt-1 text-sm font-medium text-gray-700">Name:</label>
                        </div>
                        <div className="col-span-3">
                            <input style={{ color: 'black' }} type="text" name="name" id="name" value={currentPersona.name} onChange={handleChange} className="p-2 border rounded-md w-full" />
                        </div>

                        {/* is_default field */}
                        <div className="col-span-1">
                            <label htmlFor="is_default" className="block pt-1 text-sm font-medium text-gray-700">Is Default:</label>
                        </div>
                        <div className="col-span-3">
                            <select
                                style={{ color: 'black' }}
                                name="is_default"
                                id="is_default"
                                value={String(currentPersona.is_default)}
                                onChange={handleChange}
                                className="p-2 border rounded-md w-full bg-white"
                            >
                                <option style={{ color: 'black' }} value="true">True</option>
                                <option style={{ color: 'black' }} value="false">False</option>
                            </select>
                        </div>

                        {/* has_index field */}
                        <div className="col-span-1">
                            <label htmlFor="has_index" className="block pt-1 text-sm font-medium text-gray-700">Has Index:</label>
                        </div>
                        <div className="col-span-3">
                            <select
                                style={{ color: 'black' }}
                                name="has_index"
                                id="has_index"
                                value={String(currentPersona.has_index)}
                                onChange={handleChange}
                                className="p-2 border rounded-md w-full bg-white"
                            >
                                <option style={{ color: 'black' }} value="true">True</option>
                                <option style={{ color: 'black' }} value="false">False</option>
                            </select>
                        </div>

                        {/* index_top_k field */}
                        {showIndexFields && (
                            <>
                                <div className="col-span-1">
                                    <label htmlFor="index_top_k" className="block pt-1 text-sm font-medium text-gray-700">Chunk Count:</label>
                                </div>
                                <div className="col-span-3">
                                    <input
                                        style={{ color: 'black' }}
                                        type="number"
                                        name="index_top_k"
                                        id="index_top_k"
                                        value={currentPersona.index_top_k}
                                        onChange={handleChange}
                                        className="p-2 border rounded-md w-full"
                                    />
                                </div>
                            </>
                        )}

                        {/* system_prompt field */}
                        <div className="col-span-1 flex items-center">
                            <label htmlFor="system_prompt" className="block pt-1 text-sm font-medium text-gray-700">System Prompt:</label>
                        </div>
                        <div className="col-span-3">
                            <textarea
                                style={{ color: 'black' }}
                                name="system_prompt"
                                id="system_prompt"
                                value={currentPersona.system_prompt}
                                onChange={handleChange}
                                rows={4}
                                className="p-2 border rounded-md w-full"
                            />
                        </div>

                        {/* max_tokens field */}
                        <div className="col-span-1 flex items-center">
                            <label htmlFor="max_tokens" className="block pt-1 text-sm font-medium text-gray-700">Max Tokens:</label>
                            <IconInfoCircle size={17} className="text-blue-500 ml-[16px] mt-1" data-tooltip-id="max-tokens-tooltip" data-tooltip-place='right' />
                            <Tooltip id="max-tokens-tooltip" className='max-w-[55%]'>
                                <div >
                                    <span className='whitespace-normal font-large'>
                                        This is the maximum number of tokens to generate in the response. Shorter responses will be generated if the model reaches the end of the prompt before reaching the max token limit. Recommend leaving this at the default value.
                                    </span>
                                </div>
                            </Tooltip>
                        </div>
                        <div className="col-span-3">
                            <input
                                type="number"
                                name="max_tokens"
                                id="max_tokens"
                                value={currentPersona.max_tokens}
                                onChange={handleChange}
                                className="p-2 border rounded-md w-full text-black"
                            />
                        </div>

                        {/* temperature field */}
                        <div className="col-span-1 flex items-center">
                            <label htmlFor="temperature" className="block pt-1 text-sm font-medium text-gray-700">Temperature:</label>
                            <IconInfoCircle size={17} className="text-blue-500 ml-[10px] mt-1" data-tooltip-id="temperature-tooltip" data-tooltip-place='right' />
                            <Tooltip id="temperature-tooltip" className='max-w-[55%]'>
                                <div >
                                    <span className='whitespace-normal font-large'>
                                        The temperature value controls the randomness of the model. Lower values will generate more predictable responses, while higher values will generate more random responses. Recommend leaving this at the default value.
                                    </span>
                                </div>
                            </Tooltip>
                        </div>
                        <div className="col-span-3">
                            <input
                                type="number"
                                name="temperature"
                                id="temperature"
                                min={0}
                                max={1}
                                step={0.1}
                                value={currentPersona.temperature}
                                onChange={handleChange}
                                className="p-2 border rounded-md w-full text-black"
                            />
                        </div>

                        {/* sharepoint_targets field */}
                        <div className="col-span-1">
                            <label htmlFor="sharepoint_targets" className="block pt-1 text-sm font-medium text-gray-700">Sharepoint Targets:</label>
                        </div>
                        <div className="col-span-3">
                            <button
                                type="button"
                                className="inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-500 text-base font-medium text-white hover:bg-blue-700 focus:outline-none sm:text-sm"
                                onClick={() => setShowSharepointTargetsModal(true)}
                            >
                                Edit Sharepoint Targets
                            </button>
                        </div>
                    </form>
                    <SharepointTargetsModal
                        show={showSharepointTargetsModal}
                        onHide={() => setShowSharepointTargetsModal(false)}
                        SharepointTargets={currentPersona.sharepoint_targets}
                        PersonaName={currentPersona.name}
                        formattedDate={formattedDate}
                        onTargetsChange={handleSharepointTargetsChange}
                    />
                </div>
                <div className="bg-white border-t">
                    {/* Fixed Action Buttons */}
                    <button type="submit" form="edit-persona-form" className="inline-flex w-full justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-500 text-base font-medium text-white hover:bg-blue-700 focus:outline-none sm:text-sm">
                        Upload changes
                    </button>
                    <button type="button" className="mt-4 inline-flex w-full justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-500 text-base font-medium text-white hover:bg-red-700 focus:outline-none sm:text-sm" onClick={props.onHide}>
                        Close
                    </button>
                </div>
            </div>
        </div>
    );
}

export default PersonaEditor;